import Phaser from 'phaser'
import GlobalEvents from '../Events/GlobalEvents'
import Panel from '../GameObjects/UI/Panel'
import TextRiffic from '../GameObjects/UI/TextRiffic'
import { parkSettings } from '../Settings'
const { starsOnRightAnswer } = parkSettings
import { Destination } from '../Enums/Destination'
import LoadingScene from './LoadingScene'
import { loadingSettings } from '../Settings'
const { defaultDelay } = loadingSettings
import { Size } from '../Enums/Size'
import { getRandomInt } from '../Utils/Random'
import Log from '../Utils/Debug'
import IJsonObject from '../Interfaces/IJsonObject'
import Button from '../GameObjects/UI/Button'
import LocalizedText from '../Utils/LocalizedText'
import DailyMissionsScene from './DailyMissionsScene'
import { Mission } from '../Enums/Mission'

export default class ParkScene extends Phaser.Scene {
    public constructor() {
        super('interact-park')
    }

    public create(): void {
        const { registry } = this
        this.sound.stopByKey('runner-music')
        if (!this.sound.get('main-menu-music').isPlaying) {
            this.sound.play('main-menu-music', { loop: true })
        }

        this.add.image(640, 360, 'interact-park-background')

        const panel: Panel = new Panel(this, 640, 320, '', Size.Large, '')
        const okButton: Button = new Button(this, 640, 550, 'button-ok', () => {
            LoadingScene.goToScene('runner', this, defaultDelay, { startingLocation: Destination.Park, destination: Destination.Home })
        }).setVisible(false)

        const dailyMissionsScene: DailyMissionsScene = this.scene.get('daily-missions') as DailyMissionsScene
        dailyMissionsScene.events.emit('mission-completed', Mission.TAKE_PET_TO_PARK)

        const questionObjects = this.registry.get('_texts')?.park?.filter(
            (q: IJsonObject) => q?.pet_type?.toLowerCase() === this.registry.get('petType')
        )
        if (questionObjects) {
            const pickedQuestionIndex: number = getRandomInt(0, questionObjects.length)
            const questionObject = questionObjects[pickedQuestionIndex]
            Log('Picked question object: ', questionObject)
            new TextRiffic(this, 700, panel.getBounds().y + 60, questionObject[`${this.registry.get('language')?.split('$')[2]}_question`])
                .setWordWrapWidth(panel.displayWidth - 50).setPosition(panel.getBounds().x + 40, panel.getBounds().y + 40)

            const feedback: TextRiffic = new TextRiffic(this, 280, panel.getBounds().y + panel.displayHeight - 200, '')
                .setWordWrapWidth(panel.displayWidth - 50).setPosition(panel.getBounds().x + 50, panel.getBounds().y + panel.displayHeight - 200).setVisible(false)

            GlobalEvents.emit('runner-started')
            const rightButton: Button = new Button(this, 500, 550, 'button-right', () => this.events.emit('question-answered', questionObject['answer'] != 0))
            const wrongButton: Button = new Button(this, 780, 550, 'button-wrong', () => this.events.emit('question-answered', questionObject['answer'] == 0))

            let starsAcquired: number = 0

            this.events.once('question-answered', (answer: boolean) => {
                if (answer) {
                    starsAcquired = starsOnRightAnswer

                    const starsCollectedTimer: Phaser.Time.TimerEvent = this.time.addEvent({
                        delay: 50,
                        callback: () => {
                            if (starsAcquired > 0) {
                                registry.set('_starsCollected', (registry.get('_starsCollected') ?? 0) + 1)
                                starsAcquired--
                                GlobalEvents.emit('runner-stars-collected', (registry.get('_starsCollected') ?? 0))
                                this.sound.play('runner-collect-stars')
                            }
                            else {
                                okButton.setVisible(true)
                                starsCollectedTimer.remove()
                            }
                        },
                        callbackScope: this,
                        loop: true
                    })
                    feedback.setText(LocalizedText(this, 'misc', 18, { defaultText: 'You got that right!! Congratulations!!' }))
                        .setVisible(true)
                    dailyMissionsScene.events.emit('mission-completed', Mission.ANSWER_QUESTION_AT_PARK)
                }
                else {
                    feedback.setText(LocalizedText(this, 'misc', 19, { defaultText: 'Aww, that isn\'t the right answer.' }))
                        .setVisible(true)
                    okButton.setVisible(true)
                }
                rightButton.setVisible(false)
                wrongButton.setVisible(false)
                Log(answer)
            })
        }
        else {
            okButton.setVisible(true)
        }
    }
}
