import Phaser from 'phaser'
import { Size } from '../../Enums/Size'
import IJsonObject from '~/Interfaces/IJsonObject'
import Log from '../../Utils/Debug'
import Panel from './Panel'
import TextRiffic from './TextRiffic'
import Overlay from './Overlay'
import LocalizedText from '../../Utils/LocalizedText'

export default class Collectibles extends Panel {
    private _scene: Phaser.Scene
    private _titleText: TextRiffic
    private _collectiblesList: HTMLElement | null
    private _collectiblesDOM: Phaser.GameObjects.DOMElement
    private _toTween: Array<any> = []

     constructor(
        scene: Phaser.Scene, 
        x: number, 
        y: number, 
    ) {
        super(scene, x, y, 'title-collectibles', Size.Large)
        this._scene = scene
        this.setInteractive()
        scene.add.existing(this)
        this.hide()
        const bounds: Phaser.Geom.Rectangle = this.getBounds()
        this._titleText = new TextRiffic(
            scene, 
            x, 
            bounds.y + 8, 
            LocalizedText(scene, 'misc', 20, { defaultText: 'Collectibles' }),
            64, 
            '#FFD48D'
        )
        .setVisible(false)
        .setWordWrapWidth(380).setAlign('center')

        const overlay: Overlay = new Overlay(scene).setInteractive().setVisible(false)

        const { registry } = scene

        const collectiblesJsonData: IJsonObject = scene.cache.json.get('collectibles')
        const collectibles = (registry.get('collectibles') as Array<IJsonObject> ?? [])

        this._collectiblesDOM = scene.add.dom(640, 410).createFromCache('collectibles-list').setVisible(false)
        Log('Collectibles Json Data:', collectiblesJsonData)
        Log('Collectibles:', collectibles)

        this._collectiblesList = document.getElementById('collectibles')
        collectibles.forEach((k: IJsonObject) => {
            const collectibleData: IJsonObject = collectiblesJsonData.filter((d: IJsonObject) => d.key === k.key).pop()
            if (collectibleData) {
                const { chance, texture } = collectibleData
                const rarity = this._getRarity(chance)

                Log('Chance of', k.key, chance, '; Texture file', texture)

                const collectibleDiv: HTMLDivElement = document.createElement('div')
                collectibleDiv.className = 'collectible-item'
                if (rarity.bgTexture) {
                    collectibleDiv.style.background = `url("${rarity.bgTexture}") no-repeat center bottom`
                    collectibleDiv.style.backgroundSize = '342px 326px'
                }

                const collectibleImage: HTMLImageElement = document.createElement('img')
                collectibleImage.src = texture

                const collectibleRarity: HTMLDivElement = document.createElement('div')
                collectibleRarity.className = 'collectible-rarity'
                collectibleRarity.textContent = `${rarity.rarity}`

                const collectibleCount: HTMLDivElement = document.createElement('div')
                collectibleCount.className = 'collectible-count'
                collectibleCount.textContent = `x ${k.count}`

                collectibleDiv.appendChild(collectibleImage)
                collectibleDiv.appendChild(collectibleRarity)
                collectibleDiv.appendChild(collectibleCount)
                this._collectiblesList?.appendChild(collectibleDiv)
            }
        })
        if (collectibles.length % 2 !== 0) {
            const collectibleDiv: HTMLDivElement = document.createElement('div')
            collectibleDiv.className = 'collectible-item'
            this._collectiblesList?.appendChild(collectibleDiv)
        }

        this._toTween = [overlay, this, this._title, this._titleText, this._closeButton, this._collectiblesDOM]
        this.on('panel-opened', () => {
            this._toTween.forEach((t, i) => {
                t?.setVisible(true)
                t?.setAlpha(0)
                t?.setDepth((scene.children.last as Phaser.GameObjects.Image).depth + 1000 + i)
                overlay.show()
            })
            scene.tweens.add({
                targets: this._toTween,
                props: {
                    alpha: { value: 1.0, duration: 300, ease: 'Quad.easeIn' }
                }
            })
            .on('complete', () => {
                scene.scene.get('ui').scene.bringToTop()
            })
        })
        this.on('panel-closed', () => {
            this._toTween.forEach((t) => {
                t?.setVisible(true)
                t?.setAlpha(1.0)
            })
            scene.tweens.add({
                targets: this._toTween,
                props: {
                    alpha: { value: 0, duration: 300, ease: 'Quad.easeOut' }
                }
            })
            .on('complete', () => {
                this._toTween.forEach((t) => {
                    t?.setVisible(false)
                })
                overlay.hide()
            })
        })
    }

    private _getRarity(chance: number): IJsonObject {
        if (chance <= 0.01) {
            return { 
                rarity: LocalizedText(this._scene, 'misc', 25, { defaultText: 'Legendary' }), 
                bgTexture: 'assets/textures/collectibles/bg/bglegendary.png'
            }
        }
        if (chance <= 0.03) {
            return { 
                rarity: LocalizedText(this._scene, 'misc', 24, { defaultText: 'Super Rare' }), 
                bgTexture: 'assets/textures/collectibles/bg/bgultrarare.png'
            }
        }
        if (chance <= 0.04) {
            return { 
                rarity: LocalizedText(this._scene, 'misc', 23, { defaultText: 'Rare' }), 
                bgTexture: 'assets/textures/collectibles/bg/bgsuperrare.png'
            }
        }
        if (chance <= 0.05) {
            return { 
                rarity: LocalizedText(this._scene, 'misc', 22, { defaultText: 'Uncommon' }), 
                bgTexture: 'assets/textures/collectibles/bg/bgrare.png'
            }
        }
        return { 
            rarity: LocalizedText(this._scene, 'misc', 21, { defaultText: 'Common' }), 
            bgTexture: null
        }
    }
}
