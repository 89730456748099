import Phaser from 'phaser'

export default class TextRiffic extends Phaser.GameObjects.Text {
    constructor(scene: Phaser.Scene, x: number, y: number, text: string, size: number = 42, color: string = '#000') {
        super(scene, x, y, text, { 
            fontFamily: 'RifficFree-Bold', 
            fontSize: `${size}px`,
            color
        })
        scene.add.existing(this)
        this.x -= this.displayWidth / 2
        this.y -= this.displayHeight / 2
    }
}
