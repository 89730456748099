import Phaser from 'phaser'
import IJsonObject from '../Interfaces/IJsonObject'

export default (scene: Phaser.Scene, translationTextGroup: string, id: number, { defaultText, suffix }: { defaultText?: string, suffix?: string } = { defaultText: '', suffix: ''}) => {
    const { registry } = scene
    const langCodeAndSuffix: string = (registry.get('language') as string).split('$')[2] + ((suffix ?? []).length > 0 ? `_${suffix}` : '')
    const _texts = registry.get('_texts')
    if (_texts) {
        if (_texts[translationTextGroup] && _texts[translationTextGroup].filter) {
            const textObject = _texts[translationTextGroup]
                .filter((t: IJsonObject) => (parseInt(t.id) ?? 0) === id)
                .pop()
            if (textObject && langCodeAndSuffix in textObject)
                return textObject[langCodeAndSuffix]
            return defaultText
        }
    }

    return defaultText
}
