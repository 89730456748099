import Phaser from 'phaser'
import Log from '../Utils/Debug'
import AudioButton from '../GameObjects/UI/AudioButton'
import GlobalEvents from '../Events/GlobalEvents'
import TextRiffic from '../GameObjects/UI/TextRiffic'

export default class UIScene extends Phaser.Scene {
    private _starCountText?: Phaser.GameObjects.Text

	public constructor() {
		super('ui')
	}

    public create(): void {
        new AudioButton(this, this.sys.game.canvas.width - 50, 50)

        this._starCountText = new TextRiffic(this, 120, 36, '               ', 60, '#FFC851')
                                .setShadow(2, 4, '#4B4355')
        this._starCountText.setText('')
        this.add.existing(this._starCountText)

        GlobalEvents.on('runner-started', () => {
            if (this._starCountText) {
                Log('Runner started!')
                this._starCountText.setText((this.registry.get('_starsCollected') ?? 0).toString())  
            }
        })

        GlobalEvents.on('runner-ended', () => {
            if (this._starCountText) {
                Log('Runner ended!')
                this._starCountText.setText('')
            }
        })

        GlobalEvents.on('runner-stars-collected', (stars: number) => {
            if (this._starCountText) {
                Log('Runner stars collected! Collected ' + stars.toString() + ' stars!')
                this._starCountText.setText(stars.toString())  
            }
        })

        this.scene.bringToTop()
    }
}
