export enum Size {
    // eslint-disable-next-line no-unused-vars
    Small,
    // eslint-disable-next-line no-unused-vars
    Medium,
    // eslint-disable-next-line no-unused-vars
    Large,
    // eslint-disable-next-line no-unused-vars
    ExtraLarge
}
