import Phaser from 'phaser'
import EnvironmentObject from './EnvironmentObject'
import { runnerSettings } from '../../Settings'
const { scrollSpeed: defaultScrollSpeed, rotation: defaultRotation, endScrollTimestepDelay } = runnerSettings
import IScrollable from '../../Interfaces/IScrollable'
import Log from '../../Utils/Debug'

export default class Environment extends Phaser.GameObjects.Sprite implements IScrollable<Environment> {
    private _scene: Phaser.Scene

    private _environmentObjects: Array<EnvironmentObject>
    private _scrollSpeed: number

    private _environmentPieces: Array<Phaser.GameObjects.Sprite> = []
    public get environmentPieces(): Array<Phaser.GameObjects.Sprite> {
        return this._environmentPieces
    }

    private _environmentContainer: Phaser.GameObjects.Container
    private _endEvent: boolean = false
    private _endPiece?: Phaser.GameObjects.Sprite
    private _isScrolling: boolean = false
    private _scrollStopTimestep: number = 0

    private _isGroup: boolean = false

    public constructor(
        scene: Phaser.Scene, 
        x: number, 
        y: number, 
        environmentObjects: Array<EnvironmentObject> = [new EnvironmentObject('runner-house01', 0, 0)],
        layer: Phaser.GameObjects.Layer, 
        scrollSpeed: number = defaultScrollSpeed, 
        rotation: number = defaultRotation
    ) {
        super(scene, x, y, environmentObjects[0].key)
        this._scene = scene

        this._environmentObjects = environmentObjects
        this._scrollSpeed = scrollSpeed
        
        this.y += environmentObjects[0].offsetY

        environmentObjects.forEach((envObject: EnvironmentObject, i: number) => {
            if (i === 0) {
                this.setScale(envObject.scaleX, envObject.scaleY)
                this.setRotation(-rotation)
                this._environmentPieces.push(this)
            }
            else {
                const lastPiece: Phaser.GameObjects.Sprite = 
                    this._environmentPieces[this._environmentPieces.length - 1] as Phaser.GameObjects.Sprite

                if (!envObject.isStartPiece && !envObject.isEndPiece) {
                    const _piece: Phaser.GameObjects.Sprite = 
                        new Phaser.GameObjects.Sprite(
                            scene, 
                            lastPiece.x + lastPiece.displayWidth + envObject.offsetX, 
                            y + envObject.offsetY, 
                            envObject.key
                        )
                    _piece.setScale(envObject.scaleX, envObject.scaleY)
                    _piece.setRotation(-rotation)
                    this._environmentPieces.push(_piece)
                }
            }
        })

        this._environmentContainer = scene.add.container()

        this._environmentPieces.forEach((piece: Phaser.GameObjects.Sprite) => {
            const _piece: Environment = piece as Environment
            _piece._scrollSpeed = scrollSpeed 
            this._environmentContainer.add(_piece)
            layer.add(this._environmentContainer)
        })
        
        this._environmentContainer.setRotation(rotation)
    }

    public update(_timestep: number, dt: number): void {
        if (this._isScrolling) {
            if(!this._endEvent) {
                this._scrollStopTimestep = _timestep
            }

            this._environmentPieces.forEach((piece: Phaser.GameObjects.Sprite) => {
                const _piece: Environment = piece as Environment
                _piece.x += 0.01 * _piece._scrollSpeed * dt
            })

            if (!this._endEvent && this._environmentPieces.length > 0) {
                if (!this._isGroup) {
                    const firstPiece: Phaser.GameObjects.Sprite = this._environmentPieces[0]
                    if (firstPiece.x < -500) {
                        firstPiece.x = 
                            this._environmentPieces[this._environmentPieces.length - 1].x +
                            this.displayWidth + this._environmentObjects[0].offsetX 

                        firstPiece.setVisible(true)

                        this._environmentPieces.splice(0, 1)
                        const envObj: EnvironmentObject = this._environmentObjects[0]
                        this._environmentObjects.splice(0, 1)

                        if (!envObj.isStartPiece) {
                            this._environmentPieces.push(firstPiece)
                            this._environmentObjects.push(envObj)
                        }
                    }
                }
                else if (this._environmentPieces[this._environmentPieces.length - 1].x < -500) {
                    this._environmentPieces.forEach((
                        piece: Phaser.GameObjects.Sprite, 
                        i: number, 
                        array: Array<Phaser.GameObjects.Sprite>
                    ) => {
                        piece.setVisible(true)

                        const previousPiece: Phaser.GameObjects.Sprite = array[i > 0 ? (i - 1) : i]
                        if (i > 0) {
                            piece.x = 
                                    previousPiece.x + 
                                    previousPiece.displayWidth + 
                                    this._environmentObjects[i].offsetX
                        }
                        else {
                            piece.x = 
                                    this._scene.sys.game.canvas.width + 
                                    piece.displayWidth + 
                                    this._environmentObjects[i].offsetX
                        }
                    }) 
                }
            }

            if (this._endEvent) {
                this._environmentPieces.forEach((piece: Phaser.GameObjects.Sprite) => {
                    if (piece.x < -500) {
                        piece.setVisible(false)
                    }
                }) 
            }

            if (_timestep - this._scrollStopTimestep >= endScrollTimestepDelay) {
                this.scrolling(false)
                this.emit('scroll-stopped')
            }

            if (this._endPiece) {
                if (this._endPiece.x < 50) {
                    this.scrolling(false)
                    this.emit('scroll-stopped')
                }
            }
        }
    }

    public scrolling(value: boolean = true): Environment {
        this._isScrolling = value
        return this
    }

    public group(): Environment {
        this._isGroup = true
        return this
    }

    public endScroll(): Environment {
        if (!this._endEvent) {
            this._endEvent = true

            this._environmentPieces.forEach((piece: Phaser.GameObjects.Sprite) => {
                const _piece: Environment = piece as Environment
                if (_piece.x > this._scene.sys.game.canvas.width + 500) {
                    _piece.setVisible(false)
                    _piece.x = -1000
                }
            })

            let lastPiecePositionX: number = this._scene.sys.game.canvas.width
            this._environmentObjects.forEach((envObject: EnvironmentObject) => {
                if (envObject.isEndPiece) {
                    const lastPiece: Phaser.GameObjects.Sprite = 
                        this._environmentPieces[this._environmentPieces.length - 1] as Phaser.GameObjects.Sprite
                    lastPiecePositionX = 
                        lastPiece.x + lastPiece.displayWidth + envObject.offsetX + 
                        100 + endScrollTimestepDelay * 100 * 2
                    const _piece: Phaser.GameObjects.Sprite = 
                        new Phaser.GameObjects.Sprite(
                            this._scene, 
                            lastPiecePositionX,  
                            this.y + envObject.offsetY, 
                            envObject.key
                        )
                    _piece.setScale(envObject.scaleX, envObject.scaleY)
                    _piece.setRotation(this.rotation)
                    this._environmentPieces.push(_piece)
                    _piece.setDepth(-1000)
                    this._endPiece = _piece
                }
            })

            this._environmentPieces.forEach((piece: Phaser.GameObjects.Sprite) => {
                const _piece: Environment = piece as Environment
                _piece._scrollSpeed = this._scrollSpeed 
                if (_piece === this._endPiece || _piece.x + _piece.displayWidth / 2 + 500 < lastPiecePositionX )
                    this._environmentContainer.add(_piece)
            })
            this.emit('scroll-stopping')
        }
        return this
    }
}
