import Phaser from 'phaser'
import LoginForm from '../GameObjects/UI/LoginForm'

export default class MainScene extends Phaser.Scene {
	public constructor() {
		super('main')
	}

    public create(): void {
        this.sound.play('main-menu-music', { loop: true })
        this.add.image(640, 360, 'splashscreen')
        
        new LoginForm(this, 370, 440)
    }

    public update(): void {

    }
}
