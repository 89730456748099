import Phaser from 'phaser'

import PreloaderScene from './Scenes/PreloaderScene'
import UIScene from './Scenes/UIScene'
import MainScene from './Scenes/MainScene'
import RunnerScene from './Scenes/RunnerScene'
import LanguageSelectionScene from './Scenes/LanguageSelectionScene'
import CharacterSelectionScene from './Scenes/CharacterSelectionScene'
import HomeScene from './Scenes/HomeScene'
import ClinicScene from './Scenes/ClinicScene'
import ParkScene from './Scenes/ParkScene'
import DataScene from './Scenes/DataScene'
import DialogScene from './Scenes/DialogScene'
import DailyMissionsScene from './Scenes/DailyMissionsScene'

const config: Phaser.Types.Core.GameConfig = {
	type: Phaser.AUTO,
    backgroundColor: '#000000',
    scale: {
        parent: 'game',
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.Center.CENTER_BOTH, 
        width: 1280,
        height: 720
    },
    dom: {
        createContainer: true
    },
	physics: {
		default: 'arcade',
		arcade: {
			gravity: { y: 1000 },
		}
	},
	scene: [
        PreloaderScene,
        UIScene,
        DialogScene,
        MainScene, 
        RunnerScene,
        LanguageSelectionScene,
        CharacterSelectionScene,
        HomeScene, 
        ClinicScene,
        ParkScene,
        DataScene,
        DailyMissionsScene
    ]
}

export default new Phaser.Game(config)
