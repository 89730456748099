import Phaser from 'phaser'
import LocalizedText from '../../Utils/LocalizedText'
import TextRiffic from '../../GameObjects/UI/TextRiffic'
import IJsonObject from '../../Interfaces/IJsonObject'
import DataScene from '../DataScene'
import { Size } from '../../Enums/Size'
import { petSizeStarsSettings } from '../../Settings'
import Log from '../../Utils/Debug'
import StageSelectPanel from '../../GameObjects/UI/StageSelectPanel'
import KnowledgeVault from '../../GameObjects/UI/KnowledgeVault'
import Collectibles from '../../GameObjects/UI/Collectibles'
import DailyMissions from '../../GameObjects/UI/DailyMissions'
import DailyMissionsScene from '../DailyMissionsScene'
import Button from '../../GameObjects/UI/Button'
import { getRandomInt } from '../../Utils/Random'

export default class InteractablesHomeScene extends Phaser.Scene {
    private _playerSprite!: Phaser.GameObjects.Sprite
    public get playerSprite(): Phaser.GameObjects.Sprite {
        return this._playerSprite
    }

    private _petSprite!: Phaser.GameObjects.Sprite
    public get petSprite(): Phaser.GameObjects.Sprite {
        return this._petSprite
    }

    private _scoreText!: TextRiffic

    private _lastDraggedStart?: Phaser.GameObjects.Container
    private _lastDraggedEnd?: Phaser.GameObjects.Container
    private _saveToFirestore!: Function
    private _totalGroup: Array<any> = []
    private _draggableGroup: Array<Phaser.GameObjects.Container> = []

    constructor() {
        super('interact-home-interactables')
    }

    public addToDepthGroup(object: any): void {
        this._totalGroup = object
    }

    public preload(): void {
        const characterInteractKey: string = `character${this.registry.get('character')}-interact0`
        this._playerSprite = this.add.sprite(470, 480, characterInteractKey).play(characterInteractKey)
        const petInteractKey: Function = () => `${this.registry.get('petType') ?? 'cat'}-size${this.registry.get('petSize')}-interact`
        const petX = this.registry.get('petX') ?? 280
        const petY = this.registry.get('petY') ?? 400
        this._petSprite = this.physics.add.sprite(petX, petY, petInteractKey()).play(petInteractKey()).setOrigin(0.5, 0.3).setGravityY(-1000)

        this._playerSprite.setScale(this._playerSprite.scaleX / 2)
        this._petSprite.setScale(this._petSprite.scaleX / 2)

        this.time.addEvent({
            delay: getRandomInt(800, 2000),
            callback: () => {
                const newPositionX = getRandomInt(20, this.sys.game.canvas.width - 20) 
                if (newPositionX > this._petSprite.x) { this._petSprite.setScale(-Math.abs(this._petSprite.scaleX), this._petSprite.scaleY)}
                if (newPositionX < this._petSprite.x) { this._petSprite.setScale(Math.abs(this._petSprite.scaleX), this._petSprite.scaleY)}
                const newPositionY = getRandomInt(this.sys.game.canvas.height / 2 + 50, this.sys.game.canvas.height - 20) 
                const newSpeed = getRandomInt(3, 30)
                this.physics.moveTo(this._petSprite,newPositionX, newPositionY, newSpeed)
                this.registry.set('petX', this._petSprite.x)
                this.registry.set('petY', this._petSprite.y)
                this._ySort()
            },
            callbackScope: this,
            loop: true
        })

        this._scoreText = new TextRiffic(this, 30, -10, '               ', 42, '#FFC851')
    }

    public create({ interactables, callback, args }: { interactables: Array<IJsonObject>, callback?: Function, args?: Array<any> }): void {
        const { registry } = this
        this._saveToFirestore = (this.scene.get('data') as DataScene).saveToFirestore
        this.scene.bringToTop()

        const interactablesJsonData: Array<IJsonObject> = this.cache.json.get('interactables')
        const draggablesData: Array<IJsonObject> = interactablesJsonData?.filter((d: IJsonObject) => d.draggable)
        const interactablesData: Array<IJsonObject> = interactablesJsonData?.filter((d: IJsonObject) => d.interactable)
        const gravityData: Array<IJsonObject> = interactablesJsonData?.filter((d: IJsonObject) => d.gravity)

        const textScore: string = LocalizedText(this, 'misc', 8, { defaultText: 'Score'})

        this.events.on('update-score', (score: number = (registry.get('stars') ?? 0)) => {
            this._scoreText.setText(`${textScore}: ${score}`)
        })

        const incrementScoreCount: Function = () => {
            if ((registry.get('_starsCollected') ?? 0) > 0) {
                const starsCollectedTimer: Phaser.Time.TimerEvent = this.scene.get('interact-home').time.addEvent({
                    delay: 50,
                    callback: async () => {
                        if ((registry.get('_starsCollected') ?? 0) > 0) {
                            registry.set('stars', (registry.get('stars') ?? 0) + 1)
                            registry.set('_starsCollected', registry.get('_starsCollected') - 1)
                            this.events.emit('update-score')
                            this.sound.play('runner-collect-stars')
                            
                            const cumulativeStarCount: number = registry.get('stars') ?? 0
                            if (cumulativeStarCount > petSizeStarsSettings[Size.ExtraLarge]) {
                                if (registry.get('petSize') < Number(Size.ExtraLarge)) {
                                    registry.set('petSize', Number(Size.ExtraLarge))
                                    registry.set('_petGrew', true)
                                    this.scene.get('interact-home').scene.restart({ startMusic: false })
                                    await this._saveToFirestore()
                                }
                            }
                            else if (cumulativeStarCount > petSizeStarsSettings[Size.Large]) {
                                if (registry.get('petSize') < Number(Size.Large)) {
                                    registry.set('petSize', Number(Size.Large))
                                    registry.set('_petGrew', true)
                                    this.scene.get('interact-home').scene.restart({ startMusic: false })
                                    await this._saveToFirestore()
                                }
                            }
                            else if (cumulativeStarCount > petSizeStarsSettings[Size.Medium]) {
                                if (registry.get('petSize') < Number(Size.Medium)) {
                                    registry.set('petSize', Number(Size.Medium))
                                    registry.set('_petGrew', true)
                                    this.scene.get('interact-home').scene.restart({ startMusic: false })
                                    await this._saveToFirestore()
                                }
                            }
                            else if (cumulativeStarCount > petSizeStarsSettings[Size.Small]) {
                                registry.set('petSize', Number(Size.Small))
                            }
                        }
                        else {
                            starsCollectedTimer.remove()
                            await this._saveToFirestore()
                        }
                    },
                    callbackScope: this.scene.get('interact-home'), 
                    loop: true
                })
            }
        }

        const dailyMissionsScene: DailyMissionsScene = this.scene.get('daily-missions') as DailyMissionsScene
        dailyMissionsScene.events.on('mission-completed-confirmed-stars-collected', () => {
            incrementScoreCount()
        })

        interactables.forEach((obj: IJsonObject) => {
            const thisData: IJsonObject = interactablesJsonData?.filter((i: IJsonObject) => i.key === obj.key)[0]

            const image: Phaser.GameObjects.Image = this.add.image(0, 0, obj.key)
                .setScale(thisData ? thisData.scaleX : 1, thisData ? thisData.scaleY : 1)
            const group: Array<any> = [image]
            interactablesJsonData?.filter((i: IJsonObject) => i.parentKey === obj.key).forEach((i: IJsonObject) => {
                group.push(this.add.image(i.offsetX, i.offsetY, i.key).setScale(i.scaleX, i.scaleY))
            })
            if (image.texture.key === 'scoreboard') {
                this._scoreText.setText(`${textScore}: ${(registry.get('stars') ?? 0) as string}`)
                group.push(this._scoreText)
                incrementScoreCount()
            }

            group.forEach((g) => { 
                g.setDataEnabled() 
                g.data.set('interactable', true)
            })

            const hasDraggable: boolean = draggablesData?.filter((d: IJsonObject) => d.key === obj.key)[0]?.draggable ?? false
            const interactable = this.add.container(
                (thisData && thisData.fixed ? thisData.x : obj.x), 
                (thisData && thisData.fixed ? thisData.y : obj.y), 
                group
            )
            .setSize(image.displayWidth, image.displayHeight)
            .setName(obj.key)
            if (interactablesData?.filter((d: IJsonObject) => d.key === obj.key)[0]?.interactable ?? false) {
                interactable.setInteractive({ draggable: hasDraggable })
            }
            if (hasDraggable) {
                interactable.setDataEnabled()
                interactable.setData('scale', interactable.scaleX / 2)
                interactable.setScale(interactable.scaleX / 2, interactable.scaleY / 2)
                interactable.setInteractive({ draggable: hasDraggable })
                this.input.setDraggable(interactable) 
                this._draggableGroup.push(interactable)
            }
            if (gravityData?.filter((g: IJsonObject) => g.key === obj.key)[0]?.gravity ?? false) {
                this.physics.world.enable(interactable)
                const interactableBody: Phaser.Physics.Arcade.Body = interactable.body as Phaser.Physics.Arcade.Body
                interactableBody.setCollideWorldBounds(true)
            }

            this.input.on('drag', (_pointer: Phaser.Input.Pointer, obj: Phaser.GameObjects.Container, dragX: number, dragY: number) => {
                this._handleDrag('drag', obj, dragX, dragY)
                this._ySort()
            }).on('dragstart', (_pointer: Phaser.Input.Pointer, obj: Phaser.GameObjects.Container) => {
                this._handleDrag('dragstart', obj, _pointer.worldX, _pointer.worldY)
            }).on('dragend', (_pointer: Phaser.Input.Pointer, obj: Phaser.GameObjects.Container) => {
                this._handleDrag('dragend', obj, _pointer.worldX, _pointer.worldY)
                this._limitDraggedObjectPositions('dragend', obj)
            })
            this.input.on('pointerup', (_pointer: Phaser.Input.Pointer, objArray: Array<Phaser.GameObjects.Container>) => {
                if (objArray[0] instanceof Phaser.GameObjects.Container) {
                    objArray[0]?.setScale((objArray[0]?.getData('scale') ?? 1.0) + 0.1)
                }
            })

            const alertBookshelf: Phaser.GameObjects.Image = this.add.image(620, 290, 'alert').setVisible(false)
            if ((registry.get('_knowledgeVaultNew') ?? []).length > 0) {
                alertBookshelf.setVisible(true)
                this.events.on('alert-bookshelf-seen', () => { 
                    alertBookshelf.setVisible(false)
                    registry.set('_knowledgeVaultNew', [])
                })
            }

            const todaysMissions: Array<IJsonObject> = registry.get('todaysMissions')
            const showAlertMissions: boolean = todaysMissions?.filter((tm: IJsonObject) => tm.completed && !tm.confirmed).length > 0
            const alertMissions: Phaser.GameObjects.Image = this.add.image(450, 260, 'alert').setVisible(showAlertMissions)
            const dailyMissionsScene: DailyMissionsScene = this.scene.get('daily-missions') as DailyMissionsScene
            dailyMissionsScene.events.on('mission-completed-confirmed', () => {
                const showAlertMissions: boolean = todaysMissions?.filter((tm: IJsonObject) => tm.completed && !tm.confirmed).length > 0
                alertMissions.setVisible(showAlertMissions)

            })

            this._totalGroup = [
                ...this._totalGroup,
                interactable,
                this._playerSprite,
                this._petSprite,
                alertBookshelf,
                alertMissions
            ]
        })

        const knowledgeVault = new KnowledgeVault(this, 640, 380)
        const collectibles = new Collectibles(this, 640, 380)
        const dailyMissions = new DailyMissions(this, 640, 380)

        this.input.on('pointerover', (_pointer: Phaser.Input.Pointer, objArray: Array<Phaser.GameObjects.Container>) => {
            if (objArray[0] instanceof Phaser.GameObjects.Container) 
                objArray[0]?.setScale((objArray[0]?.getData('scale') ?? 1.0) + 0.1)
        })
        this.input.on('pointerout', (_pointer: Phaser.Input.Pointer, objArray: Array<Phaser.GameObjects.Container>) => {
            if (objArray[0] instanceof Phaser.GameObjects.Container) 
                objArray[0]?.setScale((objArray[0]?.getData('scale') ?? 1.0))
        })
        this.input.on('pointerdown', (_pointer: Phaser.Input.Pointer, objArray: Array<Phaser.GameObjects.Container>) => {
            if (objArray[0] instanceof Phaser.GameObjects.Container) {
                objArray[0]?.setScale((objArray[0]?.getData('scale') ?? 1.0) - 0.1)
                this.sound.play('button-click')

                const pressedOn: string = (objArray.pop()?.getAll().pop() as Phaser.GameObjects.Image)?.texture.key
                if (pressedOn === 'bookshelf') {
                    knowledgeVault.show()

                    this.events.emit('alert-bookshelf-seen')
                }
                if (pressedOn === 'corner-table') {
                    collectibles.show()
                }
                if (pressedOn === 'noticeboard') {
                    dailyMissions.show()
                }
                Log('Pressed on: ' + pressedOn)
            }
        })

        if (callback) {
            if (args)  {
                if (args[0] === 'self'){
                    callback(this, [...args.map((_value, i) => i > 0)])
                }
                else {
                    callback(...args ?? '')
                }
            }
        }

        const stageSelectPanel: StageSelectPanel = new StageSelectPanel(this, 640, 360, interactables.length + 1)
        new Button(this, 1092, 340, 'interact-home-door', () => {
            if ((this.registry.get('_starsCollected') ?? 0) <= 0) {
                stageSelectPanel.show()
            }
        })

        this._ySort()
    }

    private _handleDrag(event: string, obj: Phaser.GameObjects.Container, dragX: number, dragY: number): void {
        const { registry } = this

        const interactables: Array<IJsonObject> = registry.get('interactables')
        const first: Phaser.GameObjects.Image = obj.getAt(0) as Phaser.GameObjects.Image
        interactables.forEach((i: IJsonObject) => {
            if (i.key === first.texture.key) {
                i.x = obj.x
                i.y = obj.y
            }
        })
        obj.x = dragX
        obj.y = dragY
        if (this._lastDraggedEnd === this._lastDraggedStart) {
            registry.set('interactables', interactables)
            this._lastDraggedStart = undefined
            this._lastDraggedEnd = undefined
        }
        if (event === 'dragend')
            this._lastDraggedEnd = obj
        if (event === 'dragstart')
            this._lastDraggedStart = obj
    }

    private _limitDraggedObjectPositions(event: string, obj: Phaser.GameObjects.Container): void {
        const repositionObjects: Function = (colPoints: Array<any>, dragged: Phaser.GameObjects.Container, collided: Phaser.GameObjects.Container) => {
            if (colPoints.length > 0 && dragged.name !== collided.name) {
                Log('colPoints:', colPoints)
                if (
                    collided.name === 'litter-box' || 
                    collided.name === 'food-bowl' || 
                    collided.name === 'water-tray'
                ) {
                    Log(collided.name)
                    if (dragged.x >= collided.x) {
                        dragged.x = collided.x + collided.displayWidth / 2 + collided.displayWidth * 2
                    }
                    else if (dragged.x < collided.x) {
                        dragged.x = collided.x - collided.displayWidth / 2 - collided.displayWidth * 2
                    }
                    if (dragged.y >= collided.y) {
                        dragged.y = collided.y + collided.displayHeight / 2 + collided.displayHeight * 2
                    }
                    else if (dragged.y < collided.y) {
                        dragged.y = collided.y - collided.displayHeight / 2 - collided.displayHeight * 2
                    }

                    dragged.x = Phaser.Math.Clamp(dragged.x, 10, this.sys.game.canvas.width - 10)
                    dragged.y = Phaser.Math.Clamp(dragged.y, this.sys.game.canvas.height / 2, this.sys.game.canvas.height - 10)

                    if (event === 'dragend') {
                        this.scene.launch('dialog', {
                            parentScenes: [this],
                            characterKeys: { player: '', mother: `character${this.registry.get('character')}-mom0` },
                            dialogTexts: [
                                {
                                    id: 1,
                                    en_character: 'Mother',
                                    en_conversation: LocalizedText(
                                        this, 
                                        'misc', 
                                        28, 
                                        { defaultText: 'The litter box, food, and water bowl should not be placed too close to one another.'}
                                    ),
                                    location: 'Home',
                                    pet_type: this.registry.get('petType')
                                },
                            ]
                        })
                    }
                }
            }
        }
        this._draggableGroup.forEach((d: Phaser.GameObjects.Container) => {
            const { x, y, width, height } = d.getBounds()
            const bounds2 = new Phaser.Geom.Rectangle(
                x - width,
                y - height,
                width * 2,
                height * 2
            )
            const colPoints = Phaser.Geom.Intersects.GetRectangleToRectangle(obj.getBounds(), bounds2)
            repositionObjects(colPoints, obj, d)
            repositionObjects(colPoints, d, obj)
        })
    }

    private _ySort(): void {
        this._totalGroup.sort((a: IJsonObject, b: IJsonObject) => (a.y + a.displayHeight / 3) - (b.y + b.displayHeight / 3))
        for (let i: number = 0; i < this._totalGroup.length; i++) {
            if (this._totalGroup[i].texture?.key === 'alert') {
                this._totalGroup[i].setDepth(this._totalGroup.length * 2)
            }
            else 
                this._totalGroup[i].setDepth(i)
        }
    }
}
