import Phaser from 'phaser'
import Log from '../Utils/Debug'
import Game from '../Init'
import IJsonObject from '../Interfaces/IJsonObject'
import { getRandomInt } from '../Utils/Random'
import { loadingSettings } from '../Settings'
const { defaultDelay } = loadingSettings

export default class LoadingScene extends Phaser.Scene {
    private _imageCat?: Phaser.GameObjects.Image
    private _imageCatToTheVet?: Phaser.GameObjects.Image
    private _imageAnimalHappienss?: Phaser.GameObjects.Image
    private _imageRoyalCanin?: Phaser.GameObjects.Image

    private _textBroughtToYouBy?: Phaser.GameObjects.Text
    private _textSupportedBy?: Phaser.GameObjects.Text
    private _textProgress?: Phaser.GameObjects.Text
    private _textFileId?: Phaser.GameObjects.Text
    private _textPSA?: Phaser.GameObjects.Text

    private _whiteBackground?: Phaser.GameObjects.Rectangle

    private _timeLoading?: Phaser.Time.TimerEvent

    private _loadingProgress?: number
    private _fileId?: string

    private _pickedPSAIndex?: number = 0
    private _psas?: Array<IJsonObject>
    private _languageCode?: string

    public constructor() {
        super('loading')
    }

    public update(): void {
        if (this._timeLoading)
            this._loadingProgress = this._timeLoading?.getProgress()
        this._updateShowLoadingScreen(this._loadingProgress)

        const texts = this.registry.get('_texts') ?? []
        this._languageCode = (this.registry.get('language') ?? '').split('$')[2]

        if (texts && this._languageCode) {
            this._psas = texts['psa']
        }
    }

    private _updateShowLoadingScreen(
        progress: number = 0.9, 
        showLoadingFile: boolean = false
    ): void {
        if (!this._whiteBackground) {
            this._whiteBackground = this.add.rectangle(
                0, 
                0, 
                this.sys.game.canvas.width, 
                this.sys.game.canvas.height,
                0xffffff
            ).setOrigin(0, 0)
        }

        const { width: canvasWidth, height: canvasHeight } = this.sys.game.canvas

        this._imageCat?.destroy()
        this._imageCat = this.add.image(canvasWidth / 2, canvasHeight / 2, 'loading-screen-cat')

        this._imageCatToTheVet?.destroy()
        this._imageCatToTheVet = this.add.image(canvasWidth / 2, 120, 'loading-cat-to-the-vet')

        this._imageAnimalHappienss?.destroy()
        this._imageAnimalHappienss = this.add.image(240, canvasHeight - 60, 'loading-animal-happiness')

        this._imageRoyalCanin?.destroy()
        this._imageRoyalCanin = this.add.image(canvasWidth - 160, canvasHeight - 60, 'loading-royal-canin')

        this._textBroughtToYouBy?.destroy()
        this._textBroughtToYouBy = this.add.text(120, canvasHeight - 150, 'Brought to You By:', 
            { 
                fontFamily: 'RifficFree-Bold',
                fontSize: '20px',
                color: '#52455C'
            }
        )

        this._textSupportedBy?.destroy()
        this._textSupportedBy = this.add.text(canvasWidth - 220, canvasHeight - 150, 'Supported By:', 
            { 
                fontFamily: 'RifficFree-Bold',
                fontSize: '20px',
                color: '#52455C'
            }
        )

        let statusText: string = ''
        if (showLoadingFile && this._fileId) {
            this._textFileId?.destroy()

            statusText = `Loading ${this._fileId}...`
            if (progress >= 0.9) {
                statusText = 'Loading completed!'
            }

            this._textFileId = this.add.text(
                canvasWidth / 2, 
                canvasHeight / 2 + 250, 
                statusText, 
                { 
                    fontFamily: 'RifficFree-Bold',
                    fontSize: '16px',
                    color: '#52455C'
                }
            ).setOrigin(0.5, 0.5)

            this._fileId = ''
        }

        this._textProgress?.destroy()
        this._textProgress = this.add.text(
            canvasWidth / 2,
            canvasHeight / 2 + 200, 
            `${(progress * 100).toFixed(0)}%`, 
            { 
                fontFamily: 'RifficFree-Bold',
                fontSize: '24px',
                color: '#52455C'
            }
        ).setOrigin(0.5)

        if (this._psas && this._languageCode) {
            const picked: string = this._psas[this._pickedPSAIndex ?? 0][this._languageCode]

            this._textPSA?.destroy()
            this._textPSA = this.add.text(
                canvasWidth / 2,
                canvasHeight / 2 + 150, 
                picked,
                { 
                    fontFamily: 'RifficFree-Bold',
                    fontSize: '26px',
                    color: '#52455C'
                }
            ).setOrigin(0.5).setWordWrapWidth(canvasWidth - 100).setAlign('center')
        }
    }

    public static goToScene(
        targetScene: string | Phaser.Scene, 
        fromScene: Phaser.Scene, 
        delay: number = defaultDelay,
        data: object | null = null, 
        preload: Function = () => {},
        create: Function = () => {}
    ): Phaser.Scene {
        interface ISceneInstance {
            [key: string]: any
        }

        fromScene.scene.stop()

        const loadingScene: LoadingScene & ISceneInstance 
            = fromScene.scene.get('loading') as LoadingScene & ISceneInstance ?? new LoadingScene()
        loadingScene.preload = preload
        loadingScene.create = create

        if (!fromScene.scene.get('loading'))
            fromScene.scene.add('loading', loadingScene, true)

        if (!loadingScene.load?.isLoading()) {
            Log('Loading (timed) scene: ' + targetScene)
            
            loadingScene._timeLoading = Game.scene.getScene('ui').time.delayedCall(delay, () => {
                Game.scene.start(targetScene, data ?? undefined).bringToTop(targetScene)
                Game.scene.getScene('dialog').scene.bringToTop()
                Game.scene.getScene('ui').scene.bringToTop()
                loadingScene.scene?.remove()
            })
        }

        const texts = fromScene.registry.get('_texts') ?? []
        loadingScene._languageCode = (fromScene.registry.get('language') ?? '').split('$')[2]

        if (texts && loadingScene._languageCode) {
            loadingScene._psas = texts['psa']
        }

        if (loadingScene._psas)
            loadingScene._pickedPSAIndex = getRandomInt(0, loadingScene._psas.length)

        loadingScene.load?.on('progress', (progress: number) => {
            loadingScene._updateShowLoadingScreen(progress, true)
        })
        loadingScene.load?.on('fileprogress', (file: Phaser.Loader.File) => {
            loadingScene._fileId = file.key
        })
        loadingScene.load?.on('complete', () => {
            Log('Loading (files) scene: ' + targetScene)
            if (data)
                loadingScene.scene.start(targetScene, data).remove()
            else
                loadingScene.scene.start(targetScene).remove()
        })

        return loadingScene
    }
}
