import Phaser from 'phaser'
import Log from '../Utils/Debug'
import IJsonObject from '../Interfaces/IJsonObject'

export default class DailyMissionsScene extends Phaser.Scene {
    constructor() {
        super('daily-missions')
    }

    public create(): void {
        this.events.on('mission-completed', (c: number) => {
            const { registry } = this
            if (registry.get('tutorialFirstTime')) return
            Log(`Mission id ${c} being checked...`)
            const dailyMissions: Array<IJsonObject> = registry.get('_dailyMissions') as Array<IJsonObject> ?? []
            dailyMissions.forEach((m: IJsonObject) => {
                if (c === m.id) {
                    const tm: Array<IJsonObject> = registry.get('todaysMissions')
                    tm.forEach((t: IJsonObject) => {
                        if (t.id === c) {
                            Log(`Mission id ${c} completed.`)
                            t.completed = true
                        }
                    })
                    registry.set('todaysMissions', tm)
                }
            })
        })
    }
}
