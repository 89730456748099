import Phaser from 'phaser'
import { Size } from '../../Enums/Size'
import Panel from './Panel'
import Button from './Button'
import TextRiffic from './TextRiffic'

export default class NotifyPanel extends Panel {
    private _scene: Phaser.Scene

    private _text: Phaser.GameObjects.Text

    private _notification: string = ''
    public get notification(): string {
        return this._notification
    }

    private _okButton: Button
    private _okButtonHandler: Function = () => {}
    private _okButtonArgs: Array<any> = []

    private _addOns: Array<Phaser.GameObjects.GameObject> = []
    public get addOns(): Array<Phaser.GameObjects.GameObject> {
        return this._addOns
    }
    
    constructor(scene: Phaser.Scene, x: number = 640, y: number = 360, depth: number = 2) {
        super(scene, x, y, '', Size.Small, '')
        this._scene = scene
        this.setDepth(depth)

        this._text = new TextRiffic(scene, this.x, this.y - 230, this._notification, 28, '#52485E')
                        .setDepth(depth + 1)

        this._okButton = new Button(scene, this.x, this.y + 120, 'button-ok', this._okButtonHandler, this._okButtonArgs).setDepth(depth + 2)

        this.on('panel-opened', () => {
            this._text.setVisible(true)
            this._okButton.setVisible(true)
            this._addOns.forEach((a: any) => a.setVisible(true))
        })

        this.on('panel-closed', () => {
            this._text.setVisible(false)
            this._okButton.setVisible(false)
            this._addOns.forEach((a: any) => a.setVisible(false))
        })
    }

    public setNotification(text: string, size: number = 28, color: string = '#52485E', x: number = 320, y: number = 210): NotifyPanel {
        this._notification = text
        this._text.setFontSize(size)
        this._text.setColor(color)
        this._text.setPosition(x, y)
        this._text.setText(text)
        return this
    }

    public setNotificationFontSize(size: number = 28): NotifyPanel {
        this._text.setFontSize(size)
        return this
    }

    public setNotificationFontColor(color: string = '#52485E'): NotifyPanel {
        this._text.setColor(color)
        return this
    }

    public setNotificationPosition(x: number, y: number): NotifyPanel {
        this._text.setPosition(x, y)
        return this
    }

    public setOkButtonHandler(handler: Function, ...args: Array<any>): NotifyPanel {
        this._okButtonHandler = handler
        this._okButtonArgs = args
        this._okButton.setCallback(handler, args)
        return this
    }

    public setOkButtonPosition(x: number, y: number): NotifyPanel {
        this._okButton.setPosition(x, y)
        return this
    }

    public addTo(obj: Phaser.GameObjects.GameObject): NotifyPanel {
        this._scene.add.existing(obj)
        this._addOns.push(obj)
        return this
    }

    public resetAddedTo(): NotifyPanel {
        this._addOns.forEach((a: Phaser.GameObjects.GameObject) => a.destroy())
        this._addOns = []
        return this
    }
}
