import Log from '../../Utils/Debug'
import EnvironmentObject from '../../GameObjects/Runner/EnvironmentObject'
import { getRandomInt } from '../../Utils/Random'
import IJsonObject from '../../Interfaces/IJsonObject'
import { Destination } from '../../Enums/Destination'

export const rowTop = [
    new EnvironmentObject('runner-house01', getRandomInt(100, 1000), 0),
    new EnvironmentObject('runner-house01', getRandomInt(100, 1000), 0),
    new EnvironmentObject('runner-busstop', -100, 180),
    new EnvironmentObject('runner-house01', -150, 0),
    new EnvironmentObject('runner-house01', getRandomInt(100, 1000), 0),
    new EnvironmentObject('runner-house01', getRandomInt(100, 1000), 0),
    new EnvironmentObject(`runner-tree-0${getRandomInt(1, 3)}`, getRandomInt(-12, 12), getRandomInt(-150, 100)),
    new EnvironmentObject(`runner-tree-0${getRandomInt(1, 3)}`, getRandomInt(-12, 12), getRandomInt(-150, 100)),
    new EnvironmentObject(`runner-tree-0${getRandomInt(1, 3)}`, getRandomInt(-12, 12), getRandomInt(-150, 100)),
    new EnvironmentObject(`runner-tree-0${getRandomInt(1, 3)}`, getRandomInt(-12, 12), getRandomInt(-150, 100)),
    new EnvironmentObject('runner-house01', getRandomInt(500, 1000), 0),
    new EnvironmentObject(`runner-tree-0${getRandomInt(1, 3)}`, getRandomInt(-12, 12), getRandomInt(-150, 100)),
    new EnvironmentObject(`runner-tree-0${getRandomInt(1, 3)}`, getRandomInt(-12, 12), getRandomInt(-150, 100)),
    new EnvironmentObject('runner-house01', getRandomInt(500, 1000), 0),
    new EnvironmentObject(`runner-tree-0${getRandomInt(1, 3)}`, getRandomInt(-12, 12), getRandomInt(-150, 100)),
    new EnvironmentObject('runner-house01', getRandomInt(500, 1000), 0),
    new EnvironmentObject('runner-house01', getRandomInt(100, 1000), 0),
    new EnvironmentObject('runner-house01', getRandomInt(100, 1000), 0),
    // new EnvironmentObject('runner-house01', getRandomInt(100, 1000), 0),
    // new EnvironmentObject('runner-busstop', -100, 180),
    // new EnvironmentObject('runner-house01', getRandomInt(100, 1000), 0),
    // new EnvironmentObject('runner-house01', getRandomInt(100, 1000), 0),
]
export const rowTopEdge = [
    new EnvironmentObject('runner-lamp-post-top', getRandomInt(0, 2000), 196),
    new EnvironmentObject('runner-lamp-post-top', getRandomInt(0, 2000), 196),
    new EnvironmentObject('runner-lamp-post-top', getRandomInt(0, 2000), 196),
    new EnvironmentObject('runner-lamp-post-top', getRandomInt(0, 2000), 196),
    new EnvironmentObject('runner-lamp-post-top', getRandomInt(0, 2000), 196),
    new EnvironmentObject('runner-lamp-post-top', getRandomInt(0, 2000), 196),
]

export const rowBottom = [
    new EnvironmentObject(`runner-house0${getRandomInt(2, 4)}`, getRandomInt(100, 100), 0),
    new EnvironmentObject(`runner-house0${getRandomInt(2, 4)}`, getRandomInt(100, 100), 0),
    new EnvironmentObject(`runner-house0${getRandomInt(2, 4)}`, getRandomInt(100, 1000), 0),
    new EnvironmentObject(`runner-tree-0${getRandomInt(1, 3)}`, getRandomInt(-12, 12), getRandomInt(0, 200)),
    new EnvironmentObject(`runner-tree-0${getRandomInt(1, 3)}`, getRandomInt(-12, 12), getRandomInt(0, 200)),
    new EnvironmentObject(`runner-tree-0${getRandomInt(1, 3)}`, getRandomInt(-12, 12), getRandomInt(0, 200)),
    new EnvironmentObject(`runner-tree-0${getRandomInt(1, 3)}`, getRandomInt(-12, 12), getRandomInt(0, 200)),
    new EnvironmentObject(`runner-tree-0${getRandomInt(1, 3)}`, getRandomInt(-12, 12), getRandomInt(0, 200)),
    new EnvironmentObject(`runner-house0${getRandomInt(2, 4)}`, getRandomInt(100, 1000), 0),
    new EnvironmentObject(`runner-house0${getRandomInt(2, 4)}`, getRandomInt(100, 1000), 0),
    new EnvironmentObject(`runner-house0${getRandomInt(2, 4)}`, getRandomInt(100, 1000), 0),
    new EnvironmentObject(`runner-tree-0${getRandomInt(1, 3)}`, getRandomInt(-12, 12), getRandomInt(0, 200)),
    new EnvironmentObject(`runner-tree-0${getRandomInt(1, 3)}`, getRandomInt(-12, 12), getRandomInt(0, 200)),
    new EnvironmentObject(`runner-house0${getRandomInt(2, 4)}`, getRandomInt(100, 1000), 0),
    new EnvironmentObject(`runner-house0${getRandomInt(2, 4)}`, getRandomInt(100, 1000), 0),
]

export const rowBottomEdge = [
    new EnvironmentObject('runner-lamp-post-bottom', getRandomInt(0, 2000), -296),
    new EnvironmentObject('runner-lamp-post-bottom', getRandomInt(0, 2000), -296),
    new EnvironmentObject('runner-lamp-post-bottom', getRandomInt(0, 2000), -296),
    new EnvironmentObject('runner-lamp-post-bottom', getRandomInt(0, 2000), -296),
    new EnvironmentObject('runner-lamp-post-bottom', getRandomInt(0, 2000), -296),
]

export const generateCollectibles = (
            { startHeight, maxHeight, startStep = 70, step = 10, key = 'star', gap = 50, offsetX = getRandomInt(1000, 3000) } :
            { startHeight: number, maxHeight: number, startStep?: number, step?: number, key?: string, gap?: number, offsetX?: number } ) :
        Array<EnvironmentObject> => {
    const collectibles: Array<EnvironmentObject> = []

    let actualMaxHeight: number = maxHeight 
    let _step: number = startStep
    for (let h: number = startHeight; h >= -maxHeight && _step > 0; h -= Math.abs(_step)) {
        const _offsetX: number = collectibles.length <= 0 ? offsetX : gap
        collectibles.push(new EnvironmentObject(key, _offsetX, h))
        _step -= step 
    }
    actualMaxHeight = collectibles[collectibles.length - 1].offsetY
    for (let h: number = actualMaxHeight; h <= startHeight && _step < startStep; h += Math.abs(_step)) {
        collectibles.push(new EnvironmentObject(key, gap, h))
        _step += step 
    }

    return collectibles
}

export const generateObstacles = (_obstaclesData: Array<object & IJsonObject>): Array<EnvironmentObject> => {
    const obstacles: Array<EnvironmentObject> = []
    for (let i: number = 0; i < 20; i++) {
        const _pickedObstacle: number = getRandomInt(0, 7)
        const obstacleData: object & IJsonObject = _obstaclesData[_pickedObstacle]
        obstacles.push(
            new EnvironmentObject(
                `obstacle-${_pickedObstacle}`, 
                getRandomInt(500, 1000) + obstacleData.offsetX, 
                obstacleData.offsetY, 
                obstacleData.scaleX, 
                obstacleData.scaleY
            )
        )
    }
    return obstacles
}

export const setStartAndDestination = (_row: Array<EnvironmentObject>, startingLocation: Destination, destination: Destination): string => {
    switch (startingLocation) {
        case Destination.Home:
            _row[0] = new EnvironmentObject('runner-house01', getRandomInt(100, 1000), 0).setAsStartPiece()
            break
        
        case Destination.Clinic: 
            _row[0] = new EnvironmentObject('runner-destination-clinic', getRandomInt(100, 1000), 0).setAsStartPiece()
            break
        
        case Destination.Park:
            _row[0] = new EnvironmentObject('runner-destination-park', getRandomInt(100, 1000), 0).setAsStartPiece()
            break
    }

    let destinationScene: string = 'interact-home'
    switch (destination) {
        case Destination.Home:
            _row.push(new EnvironmentObject('runner-house01', getRandomInt(100, 1000), 0).setAsEndPiece())
            destinationScene = 'interact-home'
            break

        case Destination.Clinic:
            _row.push(new EnvironmentObject('runner-destination-clinic', getRandomInt(100, 1000), 0).setAsEndPiece())
            destinationScene = 'interact-clinic'
            break

        case Destination.Park:
            _row.push(new EnvironmentObject('runner-destination-park', getRandomInt(100, 1000), 0).setAsEndPiece())
            destinationScene = 'interact-park'
            break
    }

    return destinationScene
}

