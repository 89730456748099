import { Size } from './Enums/Size'

export const loadingSettings = {
    defaultDelay: 3000,
}

export const runnerSettings = {
    scrollSpeed: -60,
    rotation: -0.45,
    bounce: 0.3,
    jumpVelocity: -600,

    fullCollectibleChance: false,
    endScrollTimestep: 35,
    endScrollTimestepDelay: 10
}

export const petSizeStarsSettings = {
    [Size.Small]: 0,
    [Size.Medium]: 500,
    [Size.Large]: 1500,
    [Size.ExtraLarge]: 3000
}

export const parkSettings = {
    starsOnRightAnswer: 50
}

export const dataSettings = {
    localStorageKey: 'mypet2vet-gameka'
}

export const debugSettings = {
    debugOutput: true,
}
