import Phaser from 'phaser'
import { Size } from '../../Enums/Size'
import IJsonObject from '~/Interfaces/IJsonObject'
import Log from '../../Utils/Debug'
import Panel from './Panel'
import TextRiffic from './TextRiffic'
import Overlay from './Overlay'
import LocalizedText from '../../Utils/LocalizedText'
import DailyMissionsScene from '../../Scenes/DailyMissionsScene'
import { Mission } from '../../Enums/Mission'

export default class KnowledgeVault extends Panel {
    private _titleText: TextRiffic
    private _knowledgeVaultList: HTMLElement | null
    private _knowledgeVaultDOM: Phaser.GameObjects.DOMElement
    private _toTween: Array<any> = []

     constructor(
        scene: Phaser.Scene, 
        x: number, 
        y: number, 
    ) {
        super(scene, x, y, 'title-knowledge-vault', Size.Large)
        this.setInteractive()
        scene.add.existing(this)
        this.hide()
        const bounds: Phaser.Geom.Rectangle = this.getBounds()

        if (this._title) {
            this._title.y += 20
        }
        this._titleText = new TextRiffic(
            scene, 
            x + 80, 
            bounds.y - 12, 
            LocalizedText(scene, 'misc', 15, { defaultText: 'Knowledge Vault' }),
            64, 
            '#FFD48D'
        )
        .setVisible(false)
        .setWordWrapWidth(380).setAlign('center')

        const overlay: Overlay = new Overlay(scene).setInteractive().setVisible(false)

        const { registry } = scene

        const knowledge = registry.get('knowledgeVault') as Array<IJsonObject> ?? []
        this._knowledgeVaultDOM = scene.add.dom(640, 440).createFromCache('knowledge-vault-list').setVisible(false)
        Log('Knowledge Vault:', knowledge)

        this._knowledgeVaultList = document.getElementById('knowledge-vault')
        knowledge.forEach((k: IJsonObject) => {
            const thisLanguageCode = registry.get('language').split('$')[2]
            const thisKnowledgeTitle = k[`${thisLanguageCode}_title`]
            const thisKnowledgeDescription = k[`${thisLanguageCode}_description`]
            
            const knowledgeDiv: HTMLDivElement = document.createElement('div')
            knowledgeDiv.className = 'knowledge-item'

            const knowledgeTitle: HTMLDivElement = document.createElement('div')
            knowledgeTitle.className = 'knowledge-title'
            knowledgeTitle.textContent = LocalizedText(scene, 'clinic', parseInt(k.id), { 
                defaultText: thisKnowledgeTitle ? thisKnowledgeTitle: 'Knowledge Title', 
                suffix: 'title' 
            })

            const knowledgeTitleAnchor: HTMLAnchorElement = document.createElement('a')
            knowledgeTitleAnchor.href = `#${LocalizedText(scene, 'clinic', parseInt(k.id), { 
                defaultText: thisKnowledgeTitle ? thisKnowledgeTitle: 'Knowledge Title', 
                suffix: 'title' 
            })}`
            knowledgeTitleAnchor.style.textDecoration = 'none'
            knowledgeTitleAnchor.appendChild(knowledgeTitle)

            const viewKnowledgeButton: HTMLButtonElement = document.createElement('button')
            viewKnowledgeButton.className = 'knowledge-button'
            viewKnowledgeButton.textContent = LocalizedText(scene, 'misc', 27, { defaultText: 'View' })

            const knowledgeDescription: HTMLDivElement = document.createElement('div')
            knowledgeDescription.id = `knowledge-${k.id}`
            knowledgeDescription.className = 'knowledge-description'

            knowledgeDiv.appendChild(knowledgeTitleAnchor)
            knowledgeDiv.appendChild(viewKnowledgeButton)

            const knowledgeDescriptionContainer: HTMLDivElement = document.createElement('div')
            knowledgeDescriptionContainer.className = 'knowledge-description-container'
            knowledgeDescriptionContainer.appendChild(knowledgeDescription)

            this._knowledgeVaultList?.appendChild(knowledgeDiv)
            this._knowledgeVaultList?.appendChild(knowledgeDescriptionContainer)

            viewKnowledgeButton.addEventListener('pointerdown', () => {
                scene.sound.play('button-click')
            })
            viewKnowledgeButton.addEventListener('pointerup', () => {
                for (let i: number = 0; i < document.getElementsByClassName('knowledge-description').length; i++) {
                    document.getElementsByClassName('knowledge-description')[i].textContent = null
                    const div = (document.getElementsByClassName('knowledge-description')[i] as HTMLDivElement)
                    div.style.height = '0px'
                }
                document.getElementById(`knowledge-${k.id}`)!.textContent = 
                    LocalizedText(scene, 'clinic', parseInt(k.id), { 
                        defaultText: thisKnowledgeDescription ? thisKnowledgeDescription: 'Knowledge description.', 
                        suffix: 'description' 
                    })
                const div = (document.getElementById(`knowledge-${k.id}`)! as HTMLDivElement)
                div.style.height = '100%'

                const dailyMissionsScene: DailyMissionsScene = scene.scene.get('daily-missions') as DailyMissionsScene
                dailyMissionsScene.events.emit('mission-completed', Mission.OPEN_TOPIC_IN_BOOKCASE)
            })
            this.on('panel-closed', () => {
                for (let i: number = 0; i < document.getElementsByClassName('knowledge-description').length; i++) {
                    document.getElementsByClassName('knowledge-description')[i].textContent = null
                    const div = (document.getElementsByClassName('knowledge-description')[i] as HTMLDivElement)
                    div.style.height = '0px'
                }
            })

        })

        this._toTween = [overlay, this, this._title, this._titleText, this._closeButton, this._knowledgeVaultDOM]
        this.on('panel-opened', () => {
            this._toTween.forEach((t, i) => {
                t?.setVisible(true)
                t?.setAlpha(0)
                t?.setDepth((scene.children.last as Phaser.GameObjects.Image).depth + 1000 + i)
                overlay.show()
            })
            scene.tweens.add({
                targets: this._toTween,
                props: {
                    alpha: { value: 1.0, duration: 300, ease: 'Quad.easeIn' }
                }
            })
            .on('complete', () => {
                scene.scene.get('ui').scene.bringToTop()
            })
        })
        this.on('panel-closed', () => {
            this._toTween.forEach((t) => {
                t?.setVisible(true)
                t?.setAlpha(1.0)
            })
            scene.tweens.add({
                targets: this._toTween,
                props: {
                    alpha: { value: 0, duration: 300, ease: 'Quad.easeOut' }
                }
            })
            .on('complete', () => {
                this._toTween.forEach((t) => {
                    t?.setVisible(false)
                })
                overlay.hide()
            })
        })
    }
}
