import Phaser from 'phaser'
import { runnerSettings } from '../../Settings'
const { bounce, jumpVelocity } = runnerSettings
import { Action } from '../../Enums/Action'
import { Layer } from '../../Enums/Layer'
import RunnerScene from '../../Scenes/RunnerScene'
import Log from '../../Utils/Debug'

export default class Player extends Phaser.Physics.Arcade.Sprite {
    private _scene: Phaser.Scene
    private _shadow: Phaser.GameObjects.Sprite
    private _baseY: number
    private _actionIndex: number = 0
    private _characterIndex: number
    private _key: string
    private _jumpCount: number = 0
    private _groundParticles!: Phaser.GameObjects.Particles.ParticleEmitterManager
    private _groundParticlesEmitting?: Phaser.GameObjects.Particles.ParticleEmitterManager
    private _collisionPoints?: Array<any> = []

    constructor(scene: Phaser.Scene, x: number, y: number, characterIndex: number, ground: Phaser.GameObjects.Shape) {
        super(scene, x, y, `character${characterIndex}-runner${0}`)
        this._scene = scene
        this._shadow = new Phaser.GameObjects.Sprite(scene, x - 5, y + 60, 'runner-player-shadow')
            .setScale(0.4, 0.4)
            .setAlpha(0.7)
            .setDepth(Layer.Play)
        this._baseY = y
        scene.add.existing(this._shadow)
        scene.physics.add.existing(this)
        this._characterIndex = characterIndex
        this._key = `character${characterIndex}-runner${this._actionIndex}`
        this.setTexture(this._key)
        this.setBounce(bounce)
        this.play(this._key)

        this._groundParticles = scene.add.particles('runner-player-shadow').setDepth(Layer.Play)
        this._groundParticles.createEmitter({
            speed: { min: 0, max: 900 },
            // angle: { start: 0, end: 360, steps: 64 },
            scale: { start: 0.1, end: 0 },
            blendMode: 'SCREEN',
            //active: false,
            lifespan: 200,
            gravityY: -500,
            quantity: 16,
            on: false
        })

        const runnerScene: RunnerScene = scene as RunnerScene
        this._groundParticlesEmitting = this._groundParticles.emitParticleAt(x, y + 50000)

        scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE).on('down', () => {
            if (runnerScene.isScrolling) {
                this.setAction(Action.Jump)
            }
        })
        scene.input.on('pointerdown', (pointer: Phaser.Input.Pointer, currentlyOver: Array<Phaser.GameObjects.GameObject>) => {
            if (pointer.buttons === 1 && currentlyOver.length === 0) {
                if (runnerScene.isScrolling) {
                    this.setAction(Action.Jump)
                }
            }
        })
        scene.physics.add.collider(this, ground, (_player) => {
            if (_player.body.touching.down && runnerScene.isScrolling) {
                this.setAction(Action.Run)
                this._jumpCount = 0
                this._baseY = y
                if (!this._groundParticlesEmitting) {
                    this._groundParticlesEmitting = this._groundParticles.emitParticleAt(x, y + 50)
                }
            }
        })
    }

    public update(): void {
        const diffY: number = Math.abs(this.y - this._baseY) > 0 ? Math.abs(this.y - this._baseY) : 1
        this._shadow.setScale(0.4 - 0.4 * diffY / this._baseY)
        this._shadow.setAlpha(0.7 - 0.7 * diffY / this._baseY)
    }
    
    public setAction(action: Action): void {
        this._actionIndex = action as number
        switch(this._actionIndex) {
            case Action.Idle:
                break
            
            case Action.Jump:
                if (this._jumpCount < 2) {
                    this.setVelocityY(jumpVelocity)
                    this._jumpCount++
                    this._scene.sound.play('runner-jump')         
                    if (!this._groundParticlesEmitting) {
                        this._groundParticlesEmitting = this._groundParticles.emitParticleAt(this.x + 5, this.y + 40)
                    }
                }
                this._groundParticlesEmitting = undefined
                break

            case Action.Run:
                break
        }
        this._key = `character${this._characterIndex}-runner${this._actionIndex}`
        this.play(this._key, true)
    }

    public isCollidingWith(sprite: Phaser.GameObjects.Sprite, colWidth: number = -1, colHeight: number = -1): boolean {
        this._collisionPoints = Phaser.Geom.Intersects.GetRectangleToRectangle(this.getBounds(), sprite.getBounds())
        let isColliding: boolean = false

        if (colWidth > -1 && colHeight > -1) {
            this._collisionPoints?.forEach((point) => {
                const leftX: number = sprite.x - colWidth / 2
                const rightX: number = sprite.x + colWidth / 2
                const colX: number = point.x + sprite.width / 2
                const hitX: boolean = colX >= leftX && colX <= rightX

                const topY: number = sprite.y - colHeight / 2
                const bottomY: number = sprite.y + colHeight / 2
                const colY: number = point.y + sprite.height / 2
                const hitY: boolean = colY >= topY && colY <= bottomY

                // Log(sprite.texture.key, sprite.x, sprite.y, leftX, rightX, topY, bottomY, hitX, hitY, colX, colY)

                if (hitX || hitY) {
                    isColliding = true
                }
            })
        }
        else {
            isColliding = this._collisionPoints.length > 0
        }
        return isColliding
    }
}
