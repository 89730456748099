import Phaser from 'phaser'
import IJsonObject from '../Interfaces/IJsonObject'
import { Destination } from '../Enums/Destination'
import LoadingScene from './LoadingScene'
import { loadingSettings } from '../Settings'
const { defaultDelay } = loadingSettings
import Button from '../GameObjects/UI/Button'
import Log from '../Utils/Debug'
import LocalizedText from '../Utils/LocalizedText'
import { getRandomInt } from '../Utils/Random'
import DailyMissionsScene from './DailyMissionsScene'
import { Mission } from '../Enums/Mission'

export default class ClinicScene extends Phaser.Scene {
    public constructor() {
        super('interact-clinic')
    }

    public create(): void {
        this.sound.stopByKey('runner-music')
        if (!this.sound.get('main-menu-music').isPlaying) {
            this.sound.play('main-menu-music', { loop: true })
        }

        this.add.image(640, 360, 'interact-clinic-background').setInteractive().on('pointerup', () => {
            LoadingScene.goToScene('runner', this, defaultDelay, { startingLocation: Destination.Clinic, destination: Destination.Home })
        })
        new Button(this, 170, 350, 'interact-clinic-door', () => {
        }).setDisabled().setScale(1.0)
        new Button(this, 1151, 421, 'interact-clinic-bookshelf', () => {
        }).setDisabled().setScale(1.0)

        const characterInteractKey: string = `character${this.registry.get('character')}-vet0`
        const doctorSprite: Phaser.GameObjects.Sprite = this.add.sprite(850, 400, characterInteractKey).play(characterInteractKey).setDepth(3).setVisible(false)
        const petInteractKey: Function = () => `${this.registry.get('petType') ?? 'cat'}-size${this.registry.get('petSize')}-interact`
        const petSprite = this.add.sprite(530, 250, petInteractKey()).play(petInteractKey()).setOrigin(0.5, 0.3).setDepth(5).setVisible(true)

        new Button(this, 700, 550, 'interact-clinic-table', () => {
        }).setDisabled().setScale(1.0).setDepth(doctorSprite.depth + 1)

        const dailyMissionsScene: DailyMissionsScene = this.scene.get('daily-missions') as DailyMissionsScene

        const { registry } = this

        const dialogOptions = (which: string = 'clinicConversation') => { 
            return {
                parentScenes: [this],
                characterKeys: { player: `character${registry.get('character')}-interact0`, doctor: `character${registry.get('character')}-vet0` },
                dialogTexts: registry.get('_texts') ? registry.get('_texts')[which]
                        .filter((t: IJsonObject) => 
                            t['pet_type']?.toLowerCase() === registry.get('petType') &&
                            t['location']?.toLowerCase() === this.scene.key.split('-')[1]
                        ) : [
                        {
                            id: 1,
                            en_character: 'Player',
                            en_conversation: 'Hello again, doctor!',
                            location: 'Clinic',
                            pet_type: registry.get('petType')
                        },
                        { 
                            id: 2,
                            en_character: 'Doctor',
                            en_conversation: `Hello there! How's your ${registry.get('petType')} doing?`,
                            location: 'Clinic',
                            pet_type: registry.get('petType')
                        },
                        { 
                            id: 3,
                            en_character: 'Player',
                            en_conversation: 'Can you please take a look at her?',
                            location: 'Clinic',
                            pet_type: registry.get('petType')
                        },
                    ],
            }
        }

        if (registry.get('tutorialFirstTime')) {
            const _dialogOptions = dialogOptions('opening')
            // const { dialogTexts } = _dialogOptions
            // if (dialogTexts.length > 0) {
            //     dialogTexts[1].func = () => {
            //         doctorSprite.setVisible(false)
            //     }
            //     dialogTexts[5].func = () => {
            //         petSprite.setVisible(true)
            //     }
            //     dialogTexts[4].func = () => {
            //         dialogTexts[4][`${registry.get('language').split('$')[1]}_character`] = ''
            //         dialogTexts[6][`${registry.get('language').split('$')[1]}_character`] = ''
            //         dialogTexts[7][`${registry.get('language').split('$')[1]}_character`] = ''
            //         dialogTexts[8][`${registry.get('language').split('$')[1]}_character`] = ''
            //         dialogTexts[9][`${registry.get('language').split('$')[1]}_character`] = ''
            //         doctorSprite.setVisible(true)
            //     }
            //     dialogTexts[10].func = () => {
            //         doctorSprite.setVisible(false)
            //     }
            //     dialogTexts[dialogTexts.length - 1].func = () => {
            //         doctorSprite.setVisible(true)
            //     }
            // }

            this.scene.resume('dialog', _dialogOptions)
        }
        else {
            const _dialogOptions = dialogOptions()
            const { dialogTexts } = _dialogOptions
            if (dialogTexts.length > 0) {
                let k: number = -1
                dialogTexts.forEach((d: IJsonObject, i: number) => {
                    if (d[`${registry.get('language').split('$')[2]}_conversation`].includes('[k]'))
                        k = i
                })
                Log('Dialog index for knowledge: ', k)
                Log('Dialog placeholder for knowledge: ', dialogTexts[k])

                this.events.on('knowledge-index-picked', () => {
                    if (k > -1) {
                        dialogTexts[k][`${registry.get('language').split('$')[2]}_character`] = 'Doctor'
                        const clinicTexts = registry.get('_texts')['clinic'].filter((c: IJsonObject) => c.pet_type?.toLowerCase() === registry.get('petType'))
                        const pickedKnowledgeIndex: number = getRandomInt(0, clinicTexts.length)
                        const pickedKnowledge: string = 
                            LocalizedText(this, 'clinic', pickedKnowledgeIndex + 1, { 
                                defaultText: `<3 <3 <3!!! What an absolute chonk of an adorable ${registry.get('petType')} you have there!!!`,
                                suffix: 'description'
                            })
                        Log('Knowledge picked: ', pickedKnowledge)

                        const pickedKnowledgeObject: IJsonObject = clinicTexts[pickedKnowledgeIndex]
                        const knowledgeVault = (registry.get('knowledgeVault') ?? [])
                        if (!knowledgeVault.find((value: IJsonObject) => value.id === pickedKnowledgeObject.id)) {
                            registry.set('_knowledgeVaultNew', [pickedKnowledgeObject].map(
                                (k: IJsonObject) => { 
                                    return { 
                                        id: k.id, 
                                        pet_type: registry.get('petType'),
                                        [`${registry.get('language').split('$')[2]}_title`]: LocalizedText(this, 'clinic', parseInt(k.id), { defaultText: 'Absolute Chonk', suffix: 'title'}),
                                        [`${registry.get('language').split('$')[2]}_description`]: LocalizedText(this, 'clinic', parseInt(k.id), { defaultText: `<3 <3 <3!!! What an absolute chonk of an adorable ${registry.get('petType')} you have there!!!`, suffix: 'description'})
                                    } 
                                })
                            )
                        }

                        dialogTexts[k][`${registry.get('language').split('$')[2]}_conversation`] = pickedKnowledge
                    }
                })

                this.events.emit('knowledge-index-picked')
                // if (dialogTexts.length > 8) {
                //     dialogTexts[1].func = () => {
                //         doctorSprite.setVisible(false)
                //         this.events.emit('knowledge-index-picked')
                //     }
                //     dialogTexts[2].func = () => {
                //         petSprite.setVisible(true)
                //     }
                //     dialogTexts[4].func = () => {
                //         dialogTexts[4][`${registry.get('language').split('$')[1]}_character`] = ''
                //         dialogTexts[5][`${registry.get('language').split('$')[1]}_character`] = ''
                //         dialogTexts[6][`${registry.get('language').split('$')[1]}_character`] = ''
                //         doctorSprite.setVisible(true)
                //     }
                //     dialogTexts[8].func = () => {
                //         doctorSprite.setVisible(false)
                //     }
                //     dialogTexts[dialogTexts.length - 1].func = () => {
                //         doctorSprite.setVisible(true)
                //     }
                // }
            }

            if (this.scene.get('dialog').scene.isActive())
                this.scene.resume('dialog', _dialogOptions)
            else
                this.scene.launch('dialog', _dialogOptions)
        }

        dailyMissionsScene.events.emit('mission-completed', Mission.TAKE_PET_TO_VET)

        this.scene.get('dialog').events.once('end', () => {
            LoadingScene.goToScene('runner', this, defaultDelay, { startingLocation: Destination.Clinic, destination: Destination.Home })
            dailyMissionsScene.events.emit('mission-completed', Mission.HEAR_ADVICE_AT_CLINIC)
        })
    }
}
