import Phaser from 'phaser'
import Panel from '../../GameObjects/UI/Panel'
import { Size } from '../../Enums/Size'
import Button from './Button'
import LoadingScene from '../../Scenes/LoadingScene'
import { loadingSettings } from '../../Settings'
const { defaultDelay } = loadingSettings
import { Destination } from '../../Enums/Destination'
import LocalizedText from '../../Utils/LocalizedText'
import TextRiffic from './TextRiffic'
import Overlay from './Overlay'

export default class StageSelectPanel extends Panel {
    constructor(scene: Phaser.Scene, x: number = 640, y: number = 360, depth: number = 2) {
        super(scene, x, y, 'title-stage-select', Size.Small)
        this.hide()
        this.setDepth(depth)

        const textStageSelection: string = LocalizedText(scene, 'misc', 7, { defaultText: 'STAGE SELECT' })
        const titleText: TextRiffic = new TextRiffic(scene, this.x, this.getBounds().y + 2, textStageSelection, 64, '#FFD48D')
                                    .setVisible(false)
        const overlay: Overlay = new Overlay(scene).setInteractive()

        const toClinic: Phaser.GameObjects.Sprite = scene.add.existing(new Phaser.GameObjects.Sprite(
            scene,
            x - 219 / 2 - 10,
            y + 20,
            'panel-to-clinic'
        )).setDepth(this.depth + 1).setVisible(false)
        const toPark: Phaser.GameObjects.Sprite = scene.add.existing(new Phaser.GameObjects.Sprite(
            scene,
            x + 219 / 2 + 10,
            y + 20,
            'panel-to-park'
        )).setDepth(this.depth + 1).setVisible(false)
        const goToClinic: Button = new Button(
            scene,
            x - 219 / 2 - 10,
            y + 100,
            'button-to-clinic',
            () => {
                if ((scene.registry.get('_starsCollected') ?? 0) <= 0) {
                    _toTween.forEach((t) => {
                        t?.setVisible(true)
                        t?.setAlpha(1.0)
                    })
                    scene.tweens.add({
                        targets: _toTween,
                        props: {
                            alpha: { value: 0, duration: 300, ease: 'Quad.easeOut' }
                        }
                    })
                    .on('complete', () => {
                        _toTween.forEach((t) => {
                            t?.setVisible(false)
                        })
                        overlay.hide()
                        LoadingScene.goToScene('runner', scene.scene.get('interact-home'), defaultDelay, { startingLocation: Destination.Home, destination: Destination.Clinic })
                    })
                }
            }
        ).setDepth(toClinic.depth + 1).setVisible(false)
        const goToPark: Button = new Button(
            scene,
            x + 219 / 2 + 10,
            y + 100,
            'button-to-park',
            () => {
                if ((scene.registry.get('_starsCollected') ?? 0) <= 0) {
                    _toTween.forEach((t) => {
                        t?.setVisible(true)
                        t?.setAlpha(1.0)
                    })
                    scene.tweens.add({
                        targets: _toTween,
                        props: {
                            alpha: { value: 0, duration: 300, ease: 'Quad.easeOut' }
                        }
                    })
                    .on('complete', () => {
                        _toTween.forEach((t) => {
                            t?.setVisible(false)
                        })
                        overlay.hide()
                        LoadingScene.goToScene('runner', scene.scene.get('interact-home'), defaultDelay, { startingLocation: Destination.Home, destination: Destination.Park })
                    })
                }
            }
        ).setDepth(toPark.depth + 1).setVisible(false)

        const _toTween = [overlay, this, toClinic, goToClinic, toPark, goToPark, this._title, this._closeButton, titleText]
        this.on('panel-opened', () => {
            _toTween.forEach((t, i) => {
                t?.setVisible(true)
                t?.setAlpha(0)
                t?.setDepth((scene.children.last as Phaser.GameObjects.Image).depth + 1000 + i)
                overlay.show()
            })
            scene.tweens.add({
                targets: _toTween,
                props: {
                    alpha: { value: 1.0, duration: 300, ease: 'Quad.easeIn' }
                }
            })
            .on('complete', () => {
                scene.scene.get('ui').scene.bringToTop()
            })
        })
        this.on('panel-closed', () => {
            _toTween.forEach((t) => {
                t?.setVisible(true)
                t?.setAlpha(1.0)
            })
            scene.tweens.add({
                targets: _toTween,
                props: {
                    alpha: { value: 0, duration: 300, ease: 'Quad.easeOut' }
                }
            })
            .on('complete', () => {
                _toTween.forEach((t) => {
                    t?.setVisible(false)
                })
                overlay.hide()
            })
        })
    }
}
