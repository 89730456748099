import Phaser from 'phaser'

export default class Button extends Phaser.GameObjects.Container {
    private _key: string
    private _keyDisabled: string

    private _image?: Phaser.GameObjects.Image
    public get image(): Phaser.GameObjects.Image | undefined {
        return this._image
    }

    private _sfx: string = 'button-click'

    private _disabled: boolean = false
    public get disabled(): boolean {
        return this._disabled
    }

    private _callback: Function
    public get callback(): Function {
        return this._callback
    }

    private _args: Array<any> = []

    public constructor(
        scene: Phaser.Scene, 
        x: number, 
        y: number, 
        key: string = 'button-ok',
        callback: Function = () => {},
        ...args: Array<any>
    ) {
        super(scene, x, y)
        this._callback = callback
        this._args = args

        this._key = key
        this._keyDisabled = key

        this.setSize(200, 100)
        if (key) {
            this._image = scene.add.image(0, 0, key).setDepth(this.depth + 1)
            this.add(this._image)
            this.setSize(this._image.displayWidth, this._image.displayHeight)
        }
        
        scene.add.existing(this)
        this.setInteractive()
        this._checkDisabled()

        this.on('pointerover', () => {
            if (!this._disabled) 
                this.setScale(1.1)
        })
        this.on('pointerout', () => {
            if (!this._disabled)
                this.setScale(1.0)
        })
        this.on('pointerdown', () => {
            if (!this._disabled) {
                this.setScale(0.9)
                scene.sound.play(this._sfx)
            }
        })
        this.on('pointerup', () => {
            if (!this._disabled) {
                this.setScale(1.1)
                this._callback.apply(null, this._args)
            }
        })
    }

    private _checkDisabled(isDisabled: boolean = this._disabled, keyDisabled: string = this._keyDisabled): boolean {
        if (isDisabled) {
            this._image?.setTexture(keyDisabled)
            return true
        }
        this._image?.setTexture(this._key)
        return false
    }

    public setDisabled(disable: boolean = true, keyDisabled: string = this._keyDisabled): Button {
        this._disabled = disable
        this._keyDisabled = keyDisabled
        this._checkDisabled(this._disabled, keyDisabled)
        return this
    }

    public setClickSfx(key: string = 'button-click'): Button {
        this._sfx = key
        return this
    }

    public setCallback(callback: Function = () => {}, ...args: Array<any>): Button {
        this._callback = callback
        this._args = args
        return this
    }
}
