import Phaser from 'phaser'
import GlobalEvents from '../../Events/GlobalEvents'

export default class CharacterSelection extends Phaser.Physics.Arcade.Sprite {
    private _selected: boolean = false
    public get selected(): boolean {
        return this._selected
    }

    private _index: number = -1
    public get index(): number {
        return this._index
    }

    public constructor(
        scene: Phaser.Scene, 
        x: number, 
        y: number, 
        characterIndex: number, 
        sfx: string = 'button-click'
    ) {
        super(scene, x, y, `character${characterIndex}`)
        this._index = characterIndex
        
        scene.add.existing(this)
        scene.physics.add.existing(this)
        this.setInteractive()
        this.disableBody()

        this.on('pointerover', () => {
            if (!this._selected) {
                this.setScale(1.1)
                this.setRotation(-0.08)
                this.setDepth(3)
            }
        })
        this.on('pointerout', () => {
            if (!this._selected) {
                this.setScale(1.0)
                this.setRotation(0)
                this.setDepth(0)
            }
        })
        this.on('pointerdown', () => {
            if (!this._selected)
                this.setScale(0.9)
            scene.sound.play(sfx)
        })
        this.on('pointerup', () => {
            if (!this._selected)
                this.setScale(1.1)
            this._selected = !this._selected
            this._checkSelected()
            GlobalEvents.emit('character-selected', this)
        })

        GlobalEvents.on('character-selected', (selection: CharacterSelection) => {
            if (this !== selection) {
                this._selected = false
                this._checkSelected()
            }
        })
    }

    public setFallOff(): void {
        this.removeAllListeners()
        this.enableBody(true, this.x, this.y, true, true)
        this.setMass(9000)
        this.setGravityY(-999)
        this.setVelocityY(-900)
        this.setDepth(0)
        this.setInteractive(false)
        this.setScale(1.0)
        this.setRotation(0)
    }

    private _checkSelected(isSelected: boolean = this._selected): boolean {
        if (isSelected) {
            this.setScale(1.2)
            this.setRotation(-0.35)
            this.setDepth(999)
            return true
        }
        this.setScale(1.0)
        this.setRotation(0)
        this.setDepth(0)
        return false
    }
}
