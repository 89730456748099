import Phaser from 'phaser'
import GlobalEvents from '../Events/GlobalEvents'
import IJsonObject from '../Interfaces/IJsonObject'
import Log from '../Utils/Debug'
import { dataSettings } from '../Settings'
import IFirebaseInstance from '../Interfaces/IFirebaseInstance'
const { localStorageKey } = dataSettings

export default class DataScene extends Phaser.Scene {
    private _excludedFromLocalSave: Array<string> = ['todaysMissions']
    private _immediatelySaveToFirestore: Array<string> = ['interactables', 'todaysMissions']
    private _email: string = ''
    private _saveData: any = {}

	public constructor() {
		super('data')
	}

    public create(): void {
        GlobalEvents.on('save-data-retrieved', (email: string, saveData: object) => {
            const interactablesJsonData: Array<IJsonObject> = this.cache.json.get('interactables')
            const collectiblesJsonData: IJsonObject = this.cache.json.get('collectibles')

            this._email = email
            this._saveData = saveData

            Object.keys(this._saveData).forEach((key: string) => {
                if (key === 'volume') {
                    this.sound.volume = this._saveData[key]
                }
            })
            if (!('tutorialFirstTime' in this._saveData)) {
                this._saveData['tutorialFirstTime'] = true
            }
            else if ('tutorialFirstTime' in this._saveData) {
                if (this._saveData['tutorialFirstTime']) {
                    this._saveData['collectibles'] = []
                    this._saveData['interactables'] = []
                    this._saveData['petSize'] = 0
                    this._saveData['stars'] = 0
                }
            }

            if (!('interactables' in this._saveData)) {
                this._saveData['interactables'] = []
            }
            if ('interactables' in this._saveData) {
                this._saveData['interactables'] = this._saveData['interactables'].filter((s: IJsonObject) => {
                    const filteredInitializeWith = !interactablesJsonData.filter(
                        (i: IJsonObject) => i.key === s.key && i.initializeWith
                    ).pop()
                    const removed = !interactablesJsonData.filter(
                        (i: IJsonObject) => i.key === s.key
                    ).pop()
                    return filteredInitializeWith && !removed
                })

                const initialInteractables: Array<IJsonObject> = []
                interactablesJsonData.forEach((i: IJsonObject) => {
                    if (i.initializeWith && !i.parentKey) {
                        initialInteractables.push({ key: i.key, x: i.x, y: i.y })
                    }
                })

                this._saveData['interactables'] = [
                    ...initialInteractables,
                    ...this._saveData['interactables']
                ]
            }

            if (!('collectibles' in this._saveData) || this._saveData['collectibles'].length <= 0) {
                this._saveData['collectibles'] = []
                collectiblesJsonData.forEach((collectible: IJsonObject) => {
                    this._saveData['collectibles'].push(
                        { key: collectible.key, count: 0 }
                    )
                })
            }

            Object.keys(this._saveData).forEach((key: string) => {
                this.registry.set(key, this._saveData[key])
            })
        })
        this.registry.events.on('setdata', (parent: any, key: string, data: any) => {
            this._handleData(parent, key, data)
        })
        this.registry.events.on('changedata', (parent: any, key: string, data: any, previousData: any) => {
            this._handleData(parent, key, data, previousData)
        })
    }

    private _handleData(_parent: any, key: string, data: any, previousData: any = null): void {
        // Log('Data set:', key, data)
        // Log('Previous data:', previousData)
        // Log('All set data: ', this.registry.getAll())
         
        const allSaveData: any = JSON.parse(localStorage.getItem(localStorageKey) ?? '{}') 
        if (this._email in allSaveData) {
            allSaveData[this._email] = this.registry.getAll()

            Object.keys(allSaveData[this._email]).forEach((key: string) => {
                if (key.charAt(0) === '_' || this._excludedFromLocalSave.find((val) => val === key)) {
                    delete allSaveData[this._email][key]
                }
            })
            localStorage.setItem(localStorageKey, JSON.stringify(allSaveData))
        }

        if (this._immediatelySaveToFirestore.find((val) => val === key)) {
            this.saveToFirestore()
        }
    }

    public async saveToFirestore(): Promise<void> {
        const firebase: IFirebaseInstance = this.registry.get('_firebase')

        const allSaveData: IJsonObject = {}
        const email: string = this.registry.getAll()?.email
        allSaveData[email] = this.registry.getAll()

        Object.keys(allSaveData[email]).forEach((key: string) => {
            if (key.charAt(0) === '_' || key === 'petX' || key === 'petY') {
                delete allSaveData[email][key]
            }
        })

        await firebase.setDoc('players', email, allSaveData[email])
    }

    public async refreshData(): Promise<void> {
        const { registry } = this
        const firebase: IFirebaseInstance = registry.get('_firebase')
        const language: string = registry.get('language')

        const loadTexts: Function = async () => {
            const [_e, texts] = await firebase.getDoc('texts', language)
            if (!_e) {
                Log(`Language: ${language}; Texts: ${texts}`)
                registry.set('_texts', texts)
            }
        }

        await loadTexts()
        await this.loadDailyMissions()
        this.storeDailyMissions()
    }

    public async loadDailyMissions(): Promise<void>  {
        const { registry } = this
        const firebase: IFirebaseInstance = registry.get('_firebase')

        const [_e, dailyMissions] = await firebase.getDoc('missions', 'dailyMissions')
        if (!_e) {
            Log(`Daily Missions: ${dailyMissions?.missions}`)
            registry.set('_dailyMissions', dailyMissions?.missions)

            let todaysMissions = registry.get('todaysMissions')
            if (!todaysMissions) todaysMissions = []
            
            if (todaysMissions.length && todaysMissions.length > 0 && dailyMissions?.missions.length >= todaysMissions.length) {
                for (let i: number = 0; i < dailyMissions?.missions.length; i++) {
                    if (todaysMissions[i].id !== dailyMissions?.missions[i].id) {
                        registry.set('todaysMissions', [])
                    }
                }
            }

            if (todaysMissions.length < dailyMissions?.missions.length) {
                const missions: Array<IJsonObject> = []
                dailyMissions?.missions.forEach((d: IJsonObject) => missions.push({ id: d.id, completed: false, confirmed: false }))
                registry.set('todaysMissions', missions)
            }
        }
    }

    public storeDailyMissions(): void  { 
        const { registry } = this

        const dailyMissions: Array<IJsonObject> = registry.get('_dailyMissions') as Array<IJsonObject>
        const missionTexts: Array<IJsonObject> = registry.get('_texts')?.missions
        const languageCode: string = `${registry.get('language').split('$')[2]}`

        if (dailyMissions) {
            dailyMissions.forEach((d: IJsonObject) => {
                if (missionTexts) {
                    const textObject = missionTexts.filter((m: IJsonObject) => parseInt(m.id) === parseInt(d.id)).pop()
                    if (textObject) {
                        d[languageCode] = textObject[languageCode]
                        d['points'] = parseInt(textObject['points'])
                    }
                }
            })
            registry.set('_dailyMissions', dailyMissions)
        }
    }
}
