import Phaser from 'phaser'
import { runnerSettings } from '../../Settings'
const { bounce, jumpVelocity } = runnerSettings
import { Action } from '../../Enums/Action'
import { Layer } from '../../Enums/Layer'
import RunnerScene from '../../Scenes/RunnerScene'

export default class Pet extends Phaser.Physics.Arcade.Sprite {
    private _shadow: Phaser.GameObjects.Sprite
    private _baseY: number
    private _actionIndex: number = 0
    private _petType: string
    private _petSize: number
    private _key: string
    private _jumpCount: number = 0

    constructor(scene: Phaser.Scene, x: number, y: number, petType: string, petSize: number, ground: Phaser.GameObjects.Shape) {
        super(scene, x, y, `${petType}-size${petSize}-runner${0}`)
        this._shadow = new Phaser.GameObjects.Sprite(scene, x, y + 54, 'runner-pet-shadow')
            .setScale(0.5, 0.5)
            .setAlpha(0.7)
            .setDepth(Layer.Play)
        this._baseY = y
        scene.add.existing(this._shadow)
        scene.physics.add.existing(this)
        this._petType = petType
        this._petSize = petSize
        this._key = `${petType}-size${petSize}-runner${this._actionIndex}`
        this.setTexture(this._key)
        this.setBounce(bounce)
        this.play(this._key)

        const runnerScene: RunnerScene = scene as RunnerScene

        scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE).on('down', () => {
            if (runnerScene.isScrolling) {
                this.setAction(Action.Jump)
            }
        })
        scene.input.on('pointerdown', (pointer: Phaser.Input.Pointer, currentlyOver: Array<Phaser.GameObjects.GameObject>) => {
            if (pointer.buttons === 1 && currentlyOver.length === 0) {
                if (runnerScene.isScrolling) {
                    this.setAction(Action.Jump)
                }
            }
        })
        scene.physics.add.collider(this, ground, (_pet) => {
            if (_pet.body.touching.down && runnerScene.isScrolling) {
                this.setAction(Action.Run)
                this._jumpCount = 0
            }
        })
    }

    public setAction(action: Action): void {
        this._actionIndex = action as number
        switch(this._actionIndex) {
            case Action.Idle:
                break
            
            case Action.Jump:
                if (this._jumpCount < 2) {
                    this.setVelocityY(jumpVelocity)
                    this._jumpCount++
                }
                break

            case Action.Run:
                break
        }
        this._key = `${this._petType}-size${this._petSize}-runner${this._actionIndex}`
        this.play(this._key, true)
    }

    public update(): void {
        const diffY: number = Math.abs(this.y - this._baseY) > 0 ? Math.abs(this.y - this._baseY) : 1
        this._shadow.setScale(0.5 - 0.5 * diffY / this._baseY)
        this._shadow.setAlpha(0.7 - 0.7 * diffY / this._baseY)
    }
}
