import Phaser from 'phaser'
import IFirebaseInstance from '../../Interfaces/IFirebaseInstance'
import GlobalEvents from '../../Events/GlobalEvents'
import LoadingScene from '../../Scenes/LoadingScene'
import { loadingSettings } from '../../Settings'
const { defaultDelay } = loadingSettings
import PreloaderScene from '../../Scenes/PreloaderScene'
import DataScene from '../../Scenes/DataScene'
import { dataSettings } from '../../Settings'
import Log from '../../Utils/Debug'
import IJsonObject from '../../Interfaces/IJsonObject'
const { localStorageKey } = dataSettings

export default class LoginForm extends Phaser.GameObjects.DOMElement {
    private _scene: Phaser.Scene

    public constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y, 'login-dialog')

        this._scene = scene

        scene.add.existing(this.createFromCache('login-dialog'))
        this.setOrigin(0.5, 0.5) 

        const loginForm: HTMLElement | null = document.getElementById('login-form')
        if (loginForm) {
            loginForm.addEventListener('submit', async e => {
                e.preventDefault()
                const errorText: HTMLDivElement | null =
                    document.getElementById('error') as HTMLDivElement | null
                const showErrorText: Function = (text: string) => {
                    if (errorText) {
                        errorText.textContent = text
                    }
                }
                const emailInput: HTMLInputElement | null = 
                    document.getElementById('email') as HTMLInputElement | null
                const agreedInput: HTMLInputElement | null =
                    document.getElementById('agreed') as HTMLInputElement | null
                if (emailInput && agreedInput?.checked) {
                    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    if (emailInput.value && reg.test(emailInput.value.toLowerCase())) {
                        showErrorText('Logging in...')
                        await this._loadData(emailInput.value)
                    }
                    else {
                        showErrorText('Please enter a valid email address!')
                    }
                }
                if (!agreedInput?.checked) {
                    showErrorText('Please agree with our terms and conditions before proceeding.')
                }
            })
        }
    }

    private async _loadData(email: string): Promise<void> {
        const { registry } = this._scene

        registry.set('email', email)

        const firebase: IFirebaseInstance = registry.get('_firebase')
        const allSaveData: any = JSON.parse(localStorage.getItem(localStorageKey) ?? '{}') 
        const [_e, playerDoc] = await firebase.getDoc('players', email)
        if (!_e && 'email' in playerDoc) {
            allSaveData[email] = playerDoc
        }
        if (!(email in allSaveData) || (playerDoc && !('email' in playerDoc))) {
            allSaveData[email] = registry.getAll() ?? {}
        }
        else if ('language' in allSaveData[email]) {
            Object.keys(allSaveData[email]).forEach((key: string) => {
                registry.set(key, allSaveData[email][key])
            })
        }
        Object.keys(allSaveData[email]).forEach((key: string) => {
            if (key.charAt(0) === '_') {
                delete allSaveData[email][key]
            }
        })
        allSaveData[email]['lastLoggedIn'] = new Date().toLocaleString()
        Log('Loaded previous save: ', allSaveData[email])
        if (!_e && 'email' in playerDoc) {
            await firebase.setDoc('players', email, allSaveData[email])
        }
        localStorage.setItem(localStorageKey, JSON.stringify(allSaveData))
        if (Object.keys(allSaveData).length > 0) {
            GlobalEvents.emit('save-data-retrieved', email, allSaveData[email])
        }
        
        const preloader: PreloaderScene = this._scene.scene.get('preloader') as PreloaderScene
        const language: string = registry.get('language')
        const loadTexts: Function = async () => {
            const [_e, texts] = await firebase.getDoc('texts', language)
            if (!_e) {
                Log(`Language: ${language}; Texts: ${texts}`)
                registry.set('_texts', texts)
            }
        }

        const dataScene: DataScene = this._scene.scene.get('data') as DataScene

        if (!language) {
             LoadingScene.goToScene('language-selection', this._scene, defaultDelay)
        }
        else if (registry.get('character') && registry.get('petType') && registry.get('petSize') !== null)  { 
            await loadTexts()
            await dataScene.loadDailyMissions()
            dataScene.storeDailyMissions()
            LoadingScene.goToScene(
                'interact-home', 
                this._scene, 
                defaultDelay, 
                { startMusic: true }, 
                preloader.preloadPickedCharacterAssets, 
                preloader.createPickedCharacterAssets
            ) 
        }
        else {
            await loadTexts()
            await dataScene.loadDailyMissions()
            dataScene.storeDailyMissions()
            LoadingScene.goToScene('character-selection', this._scene, defaultDelay)
        }
    }
}
