import Phaser from 'phaser'
import IJsonObject from '../Interfaces/IJsonObject'
import LoadingScene from './LoadingScene'
import { loadingSettings } from '../Settings'
const { defaultDelay } = loadingSettings
import firebase from '../Utils/Firebase'
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'
import IFirebaseInstance from '../Interfaces/IFirebaseInstance'
import Log from '../Utils/Debug'

export default class PreloaderScene extends Phaser.Scene {
	public constructor() {
		super('preloader')
	}

    public preload(): void {
        this.load.image('muted-button', 'assets/textures/ui/buttons/mutedbutton.png')
        this.load.image('unmuted-button', 'assets/textures/ui/buttons/unmutedbutton.png')

        this.load.image('loading-cat-to-the-vet', 'assets/textures/ui/loading/Cat-To-The-Vet.png')
        this.load.image('loading-screen-cat', 'assets/textures/ui/loading/catnest.png')
        this.load.image('loading-animal-happiness', 'assets/textures/ui/loading/animalhappinesslogo.png')
        this.load.image('loading-royal-canin', 'assets/textures/ui/loading/royalcaninlogo.png')

        this.load.json('interactables', 'assets/data/interactables.json')
        this.load.json('collectibles', 'assets/data/collectibles.json')

        this.load.json('characters', 'assets/data/characters.json')
        this.load.json('pets', 'assets/data/pets.json')

        this.load.json('obstacles', 'assets/data/obstacles.json')
    }

    public async create(): Promise<void> {
        this.add.rectangle(
            0, 
            0, 
            this.sys.game.canvas.width, 
            this.sys.game.canvas.height, 
            0xffffff
        ).setOrigin(0, 0)

        const characters: Array<object> = this.cache.json.get('characters') as Array<object>
        const pets: any = this.cache.json.get('pets')

        const interactables: IJsonObject = this.cache.json.get('interactables')
        const collectibles: IJsonObject = this.cache.json.get('collectibles')

        const obstacles: Array<object> = this.cache.json.get('obstacles') as Array<object>

        const _firebase: IFirebaseInstance = firebase()
        this.registry.set('_firebase', _firebase)
        const [_eAuth, authed] = await _firebase.auth()
        const [_eTexts, texts] = await _firebase.getAllDocsInCollection('texts')

        const languages = _eAuth && _eTexts ? [] : authed && texts.map((t: QueryDocumentSnapshot<DocumentData>) => t.id)
        this.registry.set('_languages', languages.length > 0 ? languages : ['English$en'])

        Log('Languages available', this.registry.get('_languages'))

        LoadingScene.goToScene('main', this, defaultDelay, null, () => {
            const loader: Phaser.Scene = this.scene.get('loading')

            loader.load.audio('main-menu-music', 'assets/audio/music/BGM_MainMenu.mp3')
            loader.load.audio('runner-music', 'assets/audio/music/BGM_RunLevel.mp3')
            loader.load.audio('runner-countdown', 'assets/audio/sfx/SFX_Level_Countdown.wav')
            loader.load.audio('button-click', 'assets/audio/sfx/SFX_Button_Click.wav')
            loader.load.audio('runner-jump', 'assets/audio/sfx/SFX_Player_Jump.mp3')
            loader.load.audio('runner-collect-stars', 'assets/audio/sfx/SFX_Player_Collect_Stars.wav')
            loader.load.audio('runner-collide-movable', 'assets/audio/sfx/SFX_Player_Hit_Obstacles.wav')
            loader.load.audio('runner-collide-immovable', 'assets/audio/sfx/SFX_Player_Hit_Holes.wav')

            loader.load.html('login-dialog', 'assets/dom/login-dialog.html')
            loader.load.html('language-dialog', 'assets/dom/language-dialog.html')
            loader.load.html('knowledge-vault-list', 'assets/dom/knowledge-vault-list.html')
            loader.load.html('collectibles-list', 'assets/dom/collectibles-list.html')
            loader.load.html('daily-missions-list', 'assets/dom/daily-missions-list.html')

            loader.load.image('splashscreen', 'assets/textures/sktch_SplashScreen_wifdog.jpg')

            loader.load.image('button-ok', 'assets/textures/ui/buttons/buttonok.png')
            loader.load.image('button-ok-disabled', 'assets/textures/ui/buttons/buttonokdisabled.png')
            loader.load.image('button-close', '/assets/textures/ui/buttons/buttonclose.png')
            loader.load.image('button-to-clinic', 'assets/textures/ui/buttons/buttontoclinic.png')
            loader.load.image('button-to-park', 'assets/textures/ui/buttons/buttontopark.png')
            loader.load.image('button-language', 'assets/textures/ui/buttons/buttonlanguage.png')
            loader.load.image('button-right', 'assets/textures/ui/buttons/buttonright.png')
            loader.load.image('button-wrong', 'assets/textures/ui/buttons/buttonwrong.png')

            loader.load.image('dialog0bg', 'assets/textures/interact/dialog/dialog0bg.png')
            loader.load.image('dialog1bg', 'assets/textures/interact/dialog/dialog1bg.png')

            loader.load.image('title-language', 'assets/textures/ui/titles/titlelanguage.png')
            loader.load.image('title-pick-your-avatar', 'assets/textures/ui/titles/titlepickyouravatar.png')
            loader.load.image('title-stage-select', 'assets/textures/ui/titles/titlestageselect.png')
            loader.load.image('title-knowledge-vault', 'assets/textures/ui/titles/titleknowledgevault.png')
            loader.load.image('title-collectibles', 'assets/textures/ui/titles/titlecollectibles.png')

            loader.load.image('panel-large', 'assets/textures/ui/panels/panel.png')
            loader.load.image('panel-medium', 'assets/textures/ui/panels/panelmedium.png')
            loader.load.image('panel-small', 'assets/textures/ui/panels/panelsmall.png')
            loader.load.image('panel-vertical', 'assets/textures/ui/panels/panelvertical.png')
            loader.load.image('panel-to-clinic', 'assets/textures/ui/panels/stageclinicbg.png')
            loader.load.image('panel-to-park', 'assets/textures/ui/panels/stageparkbg.png')

            loader.load.image('dialog-box', 'assets/textures/ui/dialoguebox.png')

            loader.load.image('alert', 'assets/textures/ui/alertdoor.png')

            loader.load.image('interact-home-background', 'assets/textures/interact/home/background.png')
            loader.load.image('interact-home-door', 'assets/textures/interact/home/door.png')
            loader.load.image('interact-home-window', 'assets/textures/interact/home/window.png')

            loader.load.image('interact-clinic-background', 'assets/textures/interact/clinic/background.png')
            loader.load.image('interact-clinic-bookshelf', 'assets/textures/interact/clinic/bookshelf.png')
            loader.load.image('interact-clinic-table', 'assets/textures/interact/clinic/clinictable.png')
            loader.load.image('interact-clinic-door', 'assets/textures/interact/clinic/door.png')
            loader.load.image('interact-park-background', 'assets/textures/interact/park/background.png')

            loader.load.image('runner-player-shadow', 'assets/textures/shadow.png')
            loader.load.image('runner-pet-shadow', 'assets/textures/catshadow.png')
            loader.load.image('runner-road', 'assets/textures/environment/ground/road.png')
            loader.load.image('runner-destination-clinic', 'assets/textures/environment/destinations/01.png')
            loader.load.image('runner-destination-park', 'assets/textures/environment/destinations/02.png')
            loader.load.image('runner-house01', 'assets/textures/environment/houses/01.png')
            loader.load.image('runner-house02', 'assets/textures/environment/houses/02.png')
            loader.load.image('runner-house03', 'assets/textures/environment/houses/03.png')
            loader.load.image('runner-busstop', 'assets/textures/environment/busstop/01.png')
            loader.load.image('runner-tree-01', 'assets/textures/environment/trees/00.png')
            loader.load.image('runner-tree-02', 'assets/textures/environment/trees/01.png')
            loader.load.image('runner-lamp-post-top', 'assets/textures/environment/decorative/lampleft.png')
            loader.load.image('runner-lamp-post-bottom', 'assets/textures/environment/decorative/lampright.png')

            loader.load.image('star', 'assets/textures/collectibles/star.png')

            characters.forEach((c: any, i: number) => {
                loader.load.image(`character${i}`, c.avatarTexture)
            })

            pets.cat.pick.forEach((cat: any) => {
                loader.load.image('pick-cat', cat)
            })
            pets.dog.pick.forEach((dog: any) => {
                loader.load.image('pick-dog', dog)
            })
            pets.cat.interact.forEach((cat: any, c: number) => {
                loader.load.spritesheet(`cat-size${c}-interact`, cat, { frameWidth: 450, frameHeight: 567 })
            })
            pets.dog.interact.forEach((dog: any, d: number) => {
                loader.load.spritesheet(`dog-size${d}-interact`, dog, { frameWidth: 450, frameHeight: 567 })
            })

            interactables.forEach((o: any) => {
                loader.load.image(`${o.key}`, o.texture)
            })

            collectibles.forEach((c: any) => {
                loader.load.image(`${c.key}`, c.texture)
            })

            obstacles.forEach((o: any, i: number) => {
                loader.load.image(`obstacle-${i}`, o.texture)
            })
        }, () => {
            const loader: Phaser.Scene = this.scene.get('loading')

            pets.cat.interact.forEach((_cat: any, c: number) => {
                loader.anims.create({
                    key: `cat-size${c}-interact`,
                    frames: loader.anims.generateFrameNames(`cat-size${c}-interact`, { start: 0, end: 24 }),
                    frameRate: 25,
                    repeat: -1
                })
            })
            pets.dog.interact.forEach((_dog: any, d: number) => {
                loader.anims.create({
                    key: `dog-size${d}-interact`,
                    frames: loader.anims.generateFrameNames(`dog-size${d}-interact`, { start: 0, end: 24 }),
                    frameRate: 25,
                    repeat: -1
                })
            })
        } ).scene.launch('data').launch('ui').launch('daily-missions')
    }

    public preloadPickedCharacterAssets(): void {
        const loader: Phaser.Scene = this.scene.get('loading')
        
        const characters: Array<any> = this.cache.json.get('characters') as Array<any>
        const c: number = this.registry.get('character') as number
        characters[c].spritesheets.runner.forEach((runner: any, r: number) => {
            loader.load.spritesheet(`character${c}-runner${r}`, runner, { frameWidth: 115, frameHeight: 115 })
        })
        characters[c].spritesheets.interact.forEach((interact: any, r: number) => {
            loader.load.spritesheet(`character${c}-interact${r}`, interact, { frameWidth: 450, frameHeight: 433 })
        })
        characters[c].spritesheets.mom.forEach((mom: any, r: number) => {
            loader.load.spritesheet(`character${c}-mom${r}`, mom, { frameWidth: 450, frameHeight: 567 })
        })
        characters[c].spritesheets.vet.forEach((vet: any, r: number) => {
            loader.load.spritesheet(`character${c}-vet${r}`, vet, { frameWidth: 450, frameHeight: 567 })
        })

        const pets: any = this.cache.json.get('pets')
        const petType: string = this.registry.get('petType') as string
        if (petType === 'cat') {
            pets.cat.runner.forEach((sizes: any, s: number) => {
                sizes.forEach((cat: any, c: number) => {
                    loader.load.spritesheet(`cat-size${s}-runner${c}`, cat, { frameWidth: 115, frameHeight: 115 })
                })
            })
        }
        if (petType === 'dog') {
            pets.dog.runner.forEach((sizes: any, s: number) => {
                sizes.forEach((dog: any, d: number) => {
                    loader.load.spritesheet(`dog-size${s}-runner${d}`, dog, { frameWidth: 115, frameHeight: 115 })
                })
            })
        }
    }

    public createPickedCharacterAssets(): void {
        const loader: Phaser.Scene = this.scene.get('loading')

        const characters: Array<any> = this.cache.json.get('characters') as Array<any>
        const c: number = this.registry.get('character') as number
        
        characters[c].spritesheets.runner.forEach((_action: any, a: number) => {
            const endFrame: number = a === 2 ? 11 : 24
            loader.anims.create({
                key: `character${c}-runner${a}`,
                frames: loader.anims.generateFrameNames(`character${c}-runner${a}`, { start: 0, end: endFrame }),
                frameRate: 25,
                repeat: -1
            })
        })
        characters[c].spritesheets.interact.forEach((_action: any, a: number) => {
            const endFrame: number = 24
            loader.anims.create({
                key: `character${c}-interact${a}`,
                frames: loader.anims.generateFrameNames(`character${c}-interact${a}`, { start: 0, end: endFrame }),
                frameRate: 25,
                repeat: -1
            })
        })
        characters[c].spritesheets.mom.forEach((_action: any, a: number) => {
            const endFrame: number = 24
            loader.anims.create({
                key: `character${c}-mom${a}`,
                frames: loader.anims.generateFrameNames(`character${c}-mom${a}`, { start: 0, end: endFrame }),
                frameRate: 25,
                repeat: -1
            })
        })
        characters[c].spritesheets.vet.forEach((_action: any, a: number) => {
            const endFrame: number = 24
            loader.anims.create({
                key: `character${c}-vet${a}`,
                frames: loader.anims.generateFrameNames(`character${c}-vet${a}`, { start: 0, end: endFrame }),
                frameRate: 25,
                repeat: -1
            })
        })

        const pets: any = this.cache.json.get('pets')
        const petType: string = this.registry.get('petType') as string
        if (petType === 'cat') {
            pets.cat.runner.forEach((sizes: any, s: number) => {
                sizes.forEach((_cat: any, c: number) => {
                    const endFrame: number = c === 2 ? 11 : 24
                    loader.anims.create({
                        key: `cat-size${s}-runner${c}`,
                        frames: loader.anims.generateFrameNames(`cat-size${s}-runner${c}`, { start: 0, end: endFrame }),
                        frameRate: 25,
                        repeat: -1
                    })
                })
            })
        }
        if (petType === 'dog') {
            pets.dog.runner.forEach((sizes: any, s: number) => {
                sizes.forEach((_dog: any, d: number) => {
                    const endFrame: number = d === 2 ? 11 : 24
                    loader.anims.create({
                        key: `dog-size${s}-runner${d}`,
                        frames: loader.anims.generateFrameNames(`dog-size${s}-runner${d}`, { start: 0, end: endFrame }),
                        frameRate: 25,
                        repeat: -1
                    })
                })
            })
        }
    }
}
