import IFirebaseInstance from '../Interfaces/IFirebaseInstance'
import WaitFor from './WaitFor'
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { getFirestore, doc, getDoc, setDoc, getDocs, collection, DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import Log from './Debug'
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: 'AIzaSyBJndrvCMp3-bY6Eku1sHkP1j4r7pB444k',
    authDomain: 'royal-canning-cat-run.firebaseapp.com',
    databaseURL: 'https://royal-canning-cat-run.firebaseio.com',
    projectId: 'royal-canning-cat-run',
    storageBucket: 'royal-canning-cat-run.appspot.com',
    messagingSenderId: '1042406805174',
    appId: '1:1042406805174:web:8d21e86bb5060587e62017',
    measurementId: 'G-HZMXB89C6M'
}

// Initialize Firebase
export default (): IFirebaseInstance => {
    const app = initializeApp(firebaseConfig)
    const firestore = getFirestore(app)
    const analytics = getAnalytics(app)

    return {
        app,
        auth: async () => await WaitFor(signInAnonymously(getAuth())),
        firestore,
        analytics,
        getDoc: async (path: string, document: string) => {
            const [_e, docSnap] = await WaitFor(getDoc(doc(firestore, path, document)))
            Log('Firebase: getDoc()', path + '/' + document)
            if (docSnap?.exists()) {
                return [_e, docSnap.data()]
            }
            return [_e, docSnap]
        },
        getAllDocsInCollection: async (collectionName: string) => {
            const [_e, querySnapshot] = await WaitFor(getDocs(collection(firestore, collectionName)))
            Log('Firebase: getAllDocsInCollection()', collectionName)
            if (!_e) {
                const collections: Array<QueryDocumentSnapshot<DocumentData>> = []
                querySnapshot?.forEach((q) => collections.push(q))
                return [_e, collections]
            }
            return [_e, querySnapshot]
        },
        setDoc: async (path: string, document: string, data: object) => {
            const [_e, docSet] = await WaitFor(setDoc(doc(firestore, path, document), data))
            Log('Firebase: setDoc()', path + '/' + document, data)
            return [_e, docSet]
        }
    }
}
