import Phaser from 'phaser'
import { Size } from '../../Enums/Size'
import IJsonObject from '~/Interfaces/IJsonObject'
import Log from '../../Utils/Debug'
import Panel from './Panel'
import TextRiffic from './TextRiffic'
import Overlay from './Overlay'
import LocalizedText from '../../Utils/LocalizedText'
import DailyMissionsScene from '../../Scenes/DailyMissionsScene'

export default class DailyMissions extends Panel {
    private _titleText: TextRiffic
    private _dailyMissionsList: HTMLElement | null
    private _dailyMissionsDOM: Phaser.GameObjects.DOMElement
    private _toTween: Array<any> = []

     constructor(
        scene: Phaser.Scene, 
        x: number, 
        y: number, 
    ) {
        super(scene, x, y, 'title-knowledge-vault', Size.Large)
        this.setInteractive()
        scene.add.existing(this)
        this.hide()
        const bounds: Phaser.Geom.Rectangle = this.getBounds()

        if (this._title) {
            this._title.y += 20
        }
        this._titleText = new TextRiffic(
            scene, 
            x + 80, 
            bounds.y - 12, 
            LocalizedText(scene, 'misc', 26, { defaultText: 'Daily Missions' }),
            64, 
            '#FFD48D'
        )
        .setVisible(false)
        .setWordWrapWidth(380).setAlign('center')

        const overlay: Overlay = new Overlay(scene).setInteractive().setVisible(false)

        const { registry } = scene

        const dailyMissions: Array<IJsonObject> = registry.get('_dailyMissions') as Array<IJsonObject> ?? []
        this._dailyMissionsDOM = scene.add.dom(640, 440).createFromCache('daily-missions-list').setVisible(false)
        Log('Daily Missions:', dailyMissions)

        const dailyMissionsScene: DailyMissionsScene = scene.scene.get('daily-missions') as DailyMissionsScene
        const todaysMissions = registry.get('todaysMissions')

        this._dailyMissionsList = document.getElementById('daily-missions')
        dailyMissions.forEach((m: IJsonObject) => {
            const thisLanguageCode = registry.get('language').split('$')[2]
            const thisMission = m[`${thisLanguageCode}`]

            Log(m)

            const isCompleted: boolean = todaysMissions.filter(
                (t: IJsonObject) => t.id === m.id && t.completed
            ).length > 0
            const isConfirmed: boolean = todaysMissions.filter(
                (t: IJsonObject) => t.id === m.id && t.confirmed
            ).length > 0
            Log('Mission id', m.id, 'completed?', isCompleted, '; Confirmed?', isConfirmed)
            
            const missionDiv: HTMLDivElement = document.createElement('div')
            missionDiv.className = 'mission-item'

            const missionTextContainerDiv: HTMLDivElement = document.createElement('div')
            missionTextContainerDiv.className = 'mission-text-container'

            const missionTextDiv: HTMLDivElement = document.createElement('div')
            missionTextDiv.className = 'mission-text'
            missionTextDiv.textContent = LocalizedText(scene, 'missions', parseInt(m.id), { 
                defaultText: thisMission ? thisMission : 'Mission'
            })

            missionTextContainerDiv.appendChild(missionTextDiv)

            const missionScoreContainerDiv: HTMLDivElement = document.createElement('div')
            missionScoreContainerDiv.className = 'mission-score-container'

            const missionScoreLabelDiv: HTMLDivElement = document.createElement('div')
            missionScoreLabelDiv.className = 'mission-score-label-text'
            missionScoreLabelDiv.textContent = 'Score'

            const missionScoreTextDiv: HTMLDivElement  = document.createElement('div')
            missionScoreTextDiv.className = 'mission-score-text'
            missionScoreTextDiv.textContent = `${m.points}`

            const missionButton: HTMLButtonElement = document.createElement('button')
            missionButton.className = 'mission-button'
            missionButton.textContent = LocalizedText(scene, 'misc', 29, { defaultText: 'Collect' })
            missionButton.disabled = !(isCompleted && !isConfirmed)

            const missionCompleted: HTMLDivElement = document.createElement('div')
            missionCompleted.className = 'mission-completed'

            missionScoreContainerDiv.appendChild(missionScoreLabelDiv)
            missionScoreContainerDiv.appendChild(missionScoreTextDiv)
            missionScoreContainerDiv.appendChild(isConfirmed ? missionCompleted : missionButton)

            missionDiv.appendChild(missionTextContainerDiv)
            missionDiv.appendChild(missionScoreContainerDiv)

            this._dailyMissionsList?.appendChild(missionDiv)

            missionButton.addEventListener('pointerdown', () => {
                if (!missionButton.disabled) {
                    scene.sound.play('button-click')
                }
            })
            missionButton.addEventListener('pointerup', () => {
                if (!missionButton.disabled) {
                    Log(`Mission ID ${m.id} button pressed!`)
                    Log(`Mission description: ${thisMission}`)
                    Log(`Mission points: ${m.points}`)
                }

                if (!missionButton.disabled) {
                    dailyMissionsScene.events.emit('mission-completed-confirmed', m.id)
                }
                // else {
                //     dailyMissionsScene.events.emit('mission-completed', m.id)
                // }
            })
            this.on('panel-closed', () => {
            })

            dailyMissionsScene.events.on('mission-completed', (c: number) => {
                if (registry.get('tutorialFirstTime')) return
                if (c === m.id) {
                    missionButton.disabled = false
                }
            })
            dailyMissionsScene.events.on('mission-completed-confirmed', (c: number) => {
                if (registry.get('tutorialFirstTime')) return
                if (c === m.id) {
                    const tm: Array<IJsonObject> = registry.get('todaysMissions')
                    tm.forEach((t: IJsonObject) => {
                        if (t.id === c) {
                            t.confirmed = true
                        }
                    })
                    registry.set('todaysMissions', tm)

                    missionScoreContainerDiv.removeChild(missionButton)
                    missionScoreContainerDiv.appendChild(missionCompleted)

                    registry.set('_starsCollected', m.points)
                    dailyMissionsScene.events.emit('mission-completed-confirmed-stars-collected')
                }
            })
        })

        this._toTween = [overlay, this, this._title, this._titleText, this._closeButton, this._dailyMissionsDOM]
        this.on('panel-opened', () => {
            this._toTween.forEach((t, i) => {
                t?.setVisible(true)
                t?.setAlpha(0)
                t?.setDepth((scene.children.last as Phaser.GameObjects.Image).depth + 1000 + i)
                overlay.show()
            })
            scene.tweens.add({
                targets: this._toTween,
                props: {
                    alpha: { value: 1.0, duration: 300, ease: 'Quad.easeIn' }
                }
            })
            .on('complete', () => {
                scene.scene.get('ui').scene.bringToTop()
            })
        })
        this.on('panel-closed', () => {
            this._toTween.forEach((t) => {
                t?.setVisible(true)
                t?.setAlpha(1.0)
            })
            scene.tweens.add({
                targets: this._toTween,
                props: {
                    alpha: { value: 0, duration: 300, ease: 'Quad.easeOut' }
                }
            })
            .on('complete', () => {
                this._toTween.forEach((t) => {
                    t?.setVisible(false)
                })
                overlay.hide()
            })
        })
    }
}
