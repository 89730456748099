import Phaser from 'phaser'
import Log from '../Utils/Debug'
import IJsonObject from '../Interfaces/IJsonObject'
import Overlay from '../GameObjects/UI/Overlay'
import TextRiffic from '../GameObjects/UI/TextRiffic'

export default class DialogScene extends Phaser.Scene {
    private _overlay!: Overlay
    private _parentScenes!: Array<Phaser.Scene> | undefined

    private _language!: string
    private _dialogTexts!:  Array<IJsonObject>
    private _currentIndex: number = 0
    private _depth: number = 100

    private _dialogBox!: Phaser.GameObjects.Image
    private _currentCharacterName!: TextRiffic
    private _currentConversationText!: TextRiffic
    private _advanceConversation!: Function

    private _characterSprites: IJsonObject = {}

    constructor() {
        super('dialog')
    }

    public create({ 
        dialogTexts, 
        parentScenes,
        index,
        depth,
        characterKeys,
        maxLines = 3
    }: { 
        dialogTexts: Array<IJsonObject>,
        parentScenes?: Array<Phaser.Scene>,
        index?: number,
        depth?: number,
        characterKeys?: IJsonObject,
        maxLines?: number
    })
    : void {
        const { width: canvasWidth, height: canvasHeight } = this.sys.game.canvas

        const initializeNewSceneData: Function = (
            _parentScenes: Array<Phaser.Scene> = parentScenes ?? [], 
            _characterKeys: IJsonObject | undefined = characterKeys, 
            _dialogTexts: Array<IJsonObject> = dialogTexts
        ) => {
            this._parentScenes = _parentScenes
            this._parentScenes?.forEach((scene: Phaser.Scene) => scene.scene.pause())
            this._currentIndex = index ?? 0
            this._dialogTexts = _dialogTexts
            Log('Current dialog text index: ', this._currentIndex)

            Object.keys(this._characterSprites).forEach((c: string) => this._characterSprites[c].destroy())
            this._characterSprites = {}
            Object.keys(_characterKeys ?? []).forEach((k: string, i: number) => {
                if (i < 2 && _characterKeys) {
                    this._characterSprites[k] = this.add.sprite(
                        i === 0 ? 230 : this.sys.game.canvas.width - 230, canvasHeight + 100, _characterKeys[k]
                    ).setOrigin(0.5, 1).play(_characterKeys[k]).setVisible(false).setDepth(this.children.getAll().length + 1).setScale(1.5, 1.5)
                }
            })
            Log('Dialog character sprites: ', this._characterSprites)
        }
        initializeNewSceneData(parentScenes)

        this._depth = depth ?? this._depth

        const { registry } = this
        const language: string = registry.get('language').split('$')[2]

        this._language = language

        this._overlay = new Overlay(this)
        this._dialogBox = this.add.image(
            canvasWidth / 2, 
            canvasHeight - 110, 
            'dialog-box'
        ).setDepth(this._depth)
        this._currentCharacterName = new TextRiffic(
            this, 
            90, 
            this._dialogBox.y - 84, 
            this._dialogTexts[this._currentIndex][`${this._language}_character`], 
            42, 
            '#fff'
        ).setDepth(this._dialogBox.depth + 1)
        this._currentConversationText = new TextRiffic(
            this, 
            0,
            0,
            this._dialogTexts[this._currentIndex][`${this._language}_conversation`], 
            42, 
            '#000'
        ).setOrigin(0, 0).setDepth(this._dialogBox.depth + 1).setWordWrapWidth(1250).setPosition(
            30,
            this._dialogBox.y - 50
        ).setMaxLines(maxLines)

        this._advanceConversation = () => {
            Log('Current scene dialog texts:')
            Log(this._dialogTexts)
            Object.keys(this._characterSprites).forEach((c: string) => this._characterSprites[c].setVisible(false))

            if (this._parentScenes) {
                const conversationOngoing: boolean = this._currentIndex < this._dialogTexts.length - 1

                if (conversationOngoing) {
                    this._currentIndex++
                    const currentDialog = this._dialogTexts[this._currentIndex]
                    Log(currentDialog)
                    Log('Current dialog text index: ', this._currentIndex)

                    if (currentDialog.func) {
                        currentDialog.func.call(this, ...(currentDialog.args ? currentDialog.args : []))
                    }

                    const characterName: string = currentDialog[`${this._language}_character`]
                    this._characterSprites[characterName?.toLowerCase()]?.setVisible(true)
                    this._currentCharacterName.setText(characterName)

                    const wrappedText: Array<string> = this._currentConversationText.getWrappedText(currentDialog[`${this._language}_conversation`])
                    Log('Wrapped text: ', wrappedText)

                    if (wrappedText.length > maxLines) {
                        const croppedText: Array<string> = wrappedText.splice(maxLines, wrappedText.length - maxLines)
                        Log('Cropped text: ', croppedText)

                        croppedText.forEach((c: string, i: number) => {
                            this._dialogTexts.splice(this._currentIndex + i + 1, 0, {
                                [`${this._language}_character`]: characterName,
                                [`${this._language}_conversation`]: c,
                            })
                        })
                    }

                    this._currentConversationText.setText(currentDialog[`${this._language}_conversation`])
                }
                else {
                    this.scene.pause()
                }
            }
        }
        this.events.on('pause', () => {
            this.input.off('pointerup')
            this.input.keyboard.removeKey(Phaser.Input.Keyboard.KeyCodes.SPACE).on('down', () => this._advanceConversation(false))
            this._parentScenes?.forEach((scene: Phaser.Scene) => scene.scene.resume())
            this._overlay.hide()
            this._dialogBox.setVisible(false)
            this._currentCharacterName.setVisible(false)
            this._currentConversationText.setVisible(false)
            Object.keys(this._characterSprites).forEach((c: string) => this._characterSprites[c].setVisible(false))
            this.events.emit('end')
        })
        this.events.on('resume', (_sys: Phaser.Scenes.Systems, 
            { 
                parentScenes, 
                dialogTexts,
                characterKeys
            }: 
            { 
                parentScenes: Array<Phaser.Scene>, 
                dialogTexts: Array<IJsonObject>,
                characterKeys: IJsonObject | undefined
            }) => {
            initializeNewSceneData(parentScenes, characterKeys, dialogTexts)
            this.goTo(this._currentIndex)
            this._currentIndex--
            this._advanceConversation()
        })
        this.startAt(this._currentIndex)
    }

    public startAt(index: number = this._currentIndex): void {
        const currentDialog = this._dialogTexts[index]
        if (currentDialog.func) {
            currentDialog.func.call(this, ...(currentDialog.args ? currentDialog.args : []))
        }
        this._characterSprites[currentDialog[`${this._language}_character`]?.toLowerCase()]?.setVisible(true)
        this.goTo(index)
    }

    public goTo(index: number = 0): void {
        this._currentIndex = index
        this._overlay.show()
        this._dialogBox.setVisible(true)
        this._currentCharacterName.setVisible(true)
        this._currentConversationText.setVisible(true)
        this.input.on('pointerup', () => this._advanceConversation(false))
        this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE).on('down', () => this._advanceConversation(false))
        this.scene.bringToTop()
        this.scene.get('ui').scene.bringToTop()
    }
}
