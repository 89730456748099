import Phaser from 'phaser'
import LoadingScene from '../../Scenes/LoadingScene'
import { loadingSettings } from '../../Settings'
const { defaultDelay } = loadingSettings
import IFirebaseInstance from '../../Interfaces/IFirebaseInstance'
import Log from '../../Utils/Debug'
import TextRiffic from './TextRiffic'
import PreloaderScene from '../../Scenes/PreloaderScene'

export default class LanguagePanel extends Phaser.GameObjects.Sprite {
    private _title?: Phaser.GameObjects.Sprite

     constructor(
        scene: Phaser.Scene, 
        x: number, 
        y: number, 
        keyTitle: string = '',
    ) {
        let key: string = 'panel-vertical' 
        super(scene, x, y, key)
        this.setInteractive()
        scene.add.existing(this)
        const bounds: Phaser.Geom.Rectangle = this.getBounds()

        if (keyTitle.length > 0) {
            this._title = scene.add.existing(new Phaser.GameObjects.Sprite(
                    scene, 
                    this.x,
                    bounds.y + 10,
                    keyTitle
                )
            ).setInteractive()
        }
        new TextRiffic(scene, x, bounds.y + 6, 'LANGUAGE', 64, '#FFD48D')

        const { registry } = scene
        const languages = registry.get('_languages') as Array<string>
        scene.add.dom(638, 388).createFromCache('language-dialog')
        const languageSelection: HTMLElement | null = document.getElementById('language-selection')
        
        const firebase: IFirebaseInstance = registry.get('_firebase')
        const preloader: PreloaderScene = scene.scene.get('preloader') as PreloaderScene

        languages.forEach((l: string) => {
            const languageButton: HTMLButtonElement = document.createElement('button')
            languageButton.className = 'language-button'
            languageButton.textContent = l.split('$')[1]
            languageSelection?.appendChild(languageButton)
            languageButton.addEventListener('click', async () => {
                Log(l)
                const [_e, texts] = await firebase.getDoc('texts', l)
                Log(`Language: ${l}; Texts: ${texts}`)
                registry.set('language', _e ? 'English$en' : l)
                if (!_e)
                    registry.set('_texts', texts)

                if (registry.get('character') && registry.get('petType') && registry.get('petSize') !== null)  { 
                    LoadingScene.goToScene('interact-home', scene, defaultDelay, { startMusic: true }, preloader.preloadPickedCharacterAssets, preloader.createPickedCharacterAssets) 
                }
                else 
                    LoadingScene.goToScene('character-selection', scene, defaultDelay)
            })
        })

    }

    public show(value: boolean = true): LanguagePanel {
        this.setVisible(value)
        this._title?.setVisible(value)
        this.emit(value ? 'panel-opened' : 'panel-closed')
        this.emit('panel-toggled', value)
        return this
    }

    public hide(): LanguagePanel {
        this.show(false)
        return this
    }
}
