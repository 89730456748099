import Phaser from 'phaser'
import LoadingScene from './LoadingScene'
import { loadingSettings } from '../Settings'
const { defaultDelay } = loadingSettings
import PreloaderScene from './PreloaderScene'
import CharacterSelection from '../GameObjects/UI/CharacterSelection'
import PetSelection from '../GameObjects/UI/PetSelection'
import Button from '../GameObjects/UI/Button'
import GlobalEvents from '../Events/GlobalEvents'
import TextRiffic from '../GameObjects/UI/TextRiffic'
import LocalizedText from '../Utils/LocalizedText'

export default class CharacterSelectionScene extends Phaser.Scene {
    private _characterSelections: Array<CharacterSelection> = []
    private _petSelections: Array<PetSelection> = []

	public constructor() {
		super('character-selection')
	}

    public create(): void {
        this.add.image(640, 360, 'dialog0bg')
        const title: Phaser.GameObjects.Image = this.add.image(this.sys.game.canvas.width / 2, 0, 'title-pick-your-avatar').setDepth(2)

        const textPickYourAvatar: string = LocalizedText(this, 'misc', 5, { defaultText: 'PICK YOUR AVATAR' })
        const textPickYourPet: string = LocalizedText(this, 'misc', 6, { defaultText: 'PICK YOUR PET' })

        const titleText: TextRiffic = new TextRiffic(this, title.x, title.getBounds().y + 50, textPickYourAvatar, 64, '#FFD48D').setDepth(title.depth + 1)

        const characters: Array<Object> = this.cache.json.get('characters') as Array<Object>
        const columns: number = 3 
        const startX: number = 405
        const gapX: number = 230
        const gapY: number = 220
        let row: number = 0
        let y: number = 250

        characters.forEach((_c: any, i: number) => {
            const column: number = i % columns
            let x: number = startX + column * gapX
            if (column === 0) {
                y += row * gapY
                row++
            }
            this._characterSelections.push(new CharacterSelection(this, x, y, i))
        })

        const okButton: Button = new Button(this, this.sys.game.canvas.width / 2, 660, 'button-ok', () => {
            this._characterSelections.forEach((selection: CharacterSelection) => {
                if (selection.selected) {
                    this.registry.set('character', selection.index)
                    titleText.setText(textPickYourPet)
                    titleText.setX(title.x - titleText.displayWidth / 2)

                    this._characterSelections.forEach((s: CharacterSelection) => {
                        s.setFallOff()
                    })
                    okButton.setDisabled()
                }
            })

            this._petSelections.forEach((selection: PetSelection) => {
                if (selection.selected) {
                    this.registry.set('petType', selection.petType)
                    this.registry.set('petSize', 0)
                    okButton.setDisabled()
                    const preloader: PreloaderScene = this.scene.get('preloader') as PreloaderScene
                    LoadingScene.goToScene('interact-home', this, defaultDelay, { startMusic: true }, preloader.preloadPickedCharacterAssets, preloader.createPickedCharacterAssets)
                }
            })
        }).setDisabled(true, 'button-ok-disabled')

        GlobalEvents.on('character-selected', (selection: CharacterSelection) => {
            okButton.setDisabled()
            for (let i = 0; i < this._characterSelections.length; i++) {
                if (selection.selected && selection === this._characterSelections[i]) {
                    okButton.setDisabled(false) 
                    break
                }
            }
        })

        GlobalEvents.once('pets-revealed', () => {
            this.tweens.add({
                targets: title,
                props: {
                    y: { value: title.y + 60, duration: 300, ease: 'Bounce.easeIn' }
                }
            })
            this.tweens.add({
                targets: titleText,
                props: {
                    y: { value: titleText.y + 60, duration: 300, ease: 'Bounce.easeIn' }
                }
            })
            this.tweens.add({
                targets: okButton,
                props: {
                    y: { value: 580, duration: 300, ease: 'Bounce.easeIn' }
                }
            })
            this._petSelections.push(new PetSelection(this, 520, 360, 'cat'))
            this._petSelections.push(new PetSelection(this, 760, 360, 'dog'))
        })

        GlobalEvents.on('pet-selected', (selection: PetSelection) => {
            okButton.setDisabled()
            for (let i = 0; i < this._petSelections.length; i++) {
                if (selection.selected) {
                    okButton.setDisabled(false) 
                    break
                }
            }
        })

        this.tweens.add({
            targets: title,
            props: {
                y: { value: title.y + 70, duration: 300, ease: 'Bounce.easeIn' }
            }
        })
        this.tweens.add({
            targets: titleText,
            props: {
                y: { value: titleText.y + 70, duration: 300, ease: 'Bounce.easeIn' }
            }
        })
        this.tweens.add({
            targets: okButton,
            props: {
                y: { value: 650, duration: 300, ease: 'Bounce.easeIn' }
            }
        })
    }

    public update(): void {
        this._characterSelections.forEach((selection: CharacterSelection) => {
            if (selection) {
                if (selection.y < -300) { 
                    this._characterSelections.splice(this._characterSelections.indexOf(selection), 1)
                    selection.disableBody().destroy()
                }
            }
        })
        if (this._characterSelections.length === 0) {
            GlobalEvents.emit('pets-revealed')
        }
    }

}
