import Phaser from 'phaser'
import Log from '../../Utils/Debug'
import IJsonObject from '../../Interfaces/IJsonObject'
import Environment from './Environment'

export default class EnvironmentRows extends Array<Environment> {
    private _rows: Array<Environment>
    public get rows(): Array<Environment> {
        return this._rows
    }

    private _endstep: number = Phaser.Math.MAX_SAFE_INTEGER
    
    constructor(scene: Phaser.Scene, arr: Array<object>) {
        const rows: Array<Environment> = []
        arr.forEach((a: IJsonObject) => {
            const env: Environment = new Environment(scene, a.x, a.y, a.row, a.layer)
            if (a.group) { env.group() }
            rows.push(env)
        })
        super(...rows)
        this._rows = rows
    }

    public update(timestep: number, dt: number, isScrolling: boolean = true): EnvironmentRows {
        if (isScrolling) {
            this._rows.forEach((environment: Environment) => { 
                environment.update(timestep, dt)
                environment.scrolling(isScrolling)
            })

            if (timestep >= this._endstep) {
                this._rows.forEach((environment: Environment) => { environment.endScroll() })
            }
        }

        return this
    }

    public setEndstep(endStep: number): EnvironmentRows {
        this._endstep = endStep
        return this
    }
}
