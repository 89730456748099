import Phaser from 'phaser'
import Log from '../Utils/Debug'
import GlobalEvents from '../Events/GlobalEvents'
import { runnerSettings } from '../Settings'
const { fullCollectibleChance, endScrollTimestep } = runnerSettings
import { getRandomInt } from '../Utils/Random'

import Player from '../GameObjects/Runner/Player'
import Pet from '../GameObjects/Runner/Pet'

import { Action } from '../Enums/Action'
import { Layer } from '../Enums/Layer'

import Road from '../GameObjects/Runner/Road'
import EnvironmentRows from '../GameObjects/Runner/EnvironmentRows'
import { rowTop, rowTopEdge, rowBottom, rowBottomEdge, generateCollectibles, generateObstacles, setStartAndDestination } from '../GameObjects/Runner/EnvironmentObjects'
import EnvironmentObject from '../GameObjects/Runner/EnvironmentObject'

import IJsonObject from '../Interfaces/IJsonObject'

import { Destination } from '../Enums/Destination'
import IRunnerSceneData from '../Interfaces/IRunnerSceneData'

import LoadingScene from './LoadingScene'
import { loadingSettings } from '../Settings'
const { defaultDelay } = loadingSettings
import TextRiffic from '../GameObjects/UI/TextRiffic'
import Overlay from '../GameObjects/UI/Overlay'
import NotifyPanel from '../GameObjects/UI/NotifyPanel'
import LocalizedText from '../Utils/LocalizedText'
import DailyMissionsScene from './DailyMissionsScene'
import { Mission } from '../Enums/Mission'

export default class RunnerScene extends Phaser.Scene {
    private _startTimestep: number = 0
    private _currentTimestep: number = 0

    private _startCountdown: number = 3
    private _countdownText?: Phaser.GameObjects.Text

    private _starsCollected: number = 0

    private _isScrolling: boolean = false
    public get isScrolling(): boolean {
        return this._isScrolling
    }

    private _hasStarted: boolean = false

    private _startingLocation!: Destination
    private _destination!: Destination

    private _overlay!: Overlay
    private _notifyPanel!: NotifyPanel

    private _layers: Array<Phaser.GameObjects.Layer> = []
    private _road? : Road
    private _environmentRows!: EnvironmentRows
    private _player?: Player
    private _pet?: Pet

    private _nearestCollectible?: Phaser.GameObjects.Sprite
    private _nearestCollectibleDistance: number = Phaser.Math.MAX_SAFE_INTEGER
    private _nearestObstacle?: Phaser.GameObjects.Sprite
    private _nearestObstacleDistance: number = Phaser.Math.MAX_SAFE_INTEGER

    private _collectiblesData!: Array<IJsonObject>
    private _collectedParticles!: Phaser.GameObjects.Particles.ParticleEmitterManager
    private _obstaclesJsonData!: Array<object & IJsonObject>
    private _obstacleParticles!: Phaser.GameObjects.Particles.ParticleEmitterManager
    private _immovableObstacleCollision: boolean = false

    private _collidedObstacleCount: number = 0

    public constructor() {
        super('runner')
    }

    public create(data: IRunnerSceneData): void {
        this._startingLocation = data.startingLocation
        this._destination = data.destination
        this._collidedObstacleCount = 0

        Log('Runner destination: ' + this._destination)

        this._overlay = new Overlay(this)
        this._notifyPanel = new NotifyPanel(this, 640, 330, (this._nearestObstacle?.depth ?? 0) + 99)
        this._notifyPanel
            .show(false)

        this._collectiblesData = (this.cache.json.get('collectibles') as Array<IJsonObject>)
            .filter((c: IJsonObject) => {
                const canAppear: boolean = (this.registry.get('_collectiblesNew') ?? []).filter((n: string) => n === c.key).length <= 0
                if (this.registry.get('tutorialFirstTime') ?? true) {
                    return c.tutorialOnly && canAppear
                }
                return !c.tutorialOnly && canAppear
            })
            .map((c: IJsonObject) => {
                c.appearedThisRun = false
                return c
            })
            .filter((c: IJsonObject) => {
                const random = Math.random()
                const willAppear = random <= c.chance
                Log('Random number:', random)
                if (willAppear) {
                    Log(c.key, 'of chance', c.chance, 'appears this run!')
                }
                return willAppear || fullCollectibleChance
            })
        Log('Collectibles this run: ', this._collectiblesData)

        this._obstaclesJsonData = this.cache.json.get('obstacles') as Array<object>

        this.sound.stopByKey('main-menu-music')

        this._initRunnerGameObjects()
        this._startRunnerCountdown()
    }

    public update(_timestep: number, dt: number): void {
        
        if (!this._hasStarted) {
            this._startTimestep = _timestep
        }
        if (this._hasStarted && this._isScrolling) {
            this._currentTimestep = _timestep - this._startTimestep
        }

        if (this._isScrolling) {
            this._road?.update(_timestep, dt)
            this._player?.update()
            this._pet?.update()

            const currentTimestep: number = Math.floor(this._currentTimestep / 1000)
            Log('Timestep elapsed: ' + currentTimestep)
        }
        else {
            this._player?.setAction(Action.Idle)
            this._pet?.setAction(Action.Idle)
        }

        this._road?.scrolling(this._isScrolling)
        this._environmentRows
            .update(Math.floor(this._currentTimestep / 1000), dt, this._isScrolling)
            .setEndstep(endScrollTimestep)

        if (this.registry.get('tutorialFirstTime') && this._hasStarted) {
            const showTutorial: Function = (
                key: string,
                nearestSprite: Phaser.GameObjects.Sprite,
                tutorialText: string,
                specificKey: string | null = null
            ): void => {
                if (!this.registry.get(`_tutorial-runner-${specificKey ?? key}`) && 
                    nearestSprite?.texture.key === key) {
                    this._isScrolling = false
                    this._player?.disableBody(true)
                    this._pet?.disableBody(true)
                    this._overlay.show()
                    const notifyImage: Phaser.GameObjects.Image =
                        new Phaser.GameObjects.Image(this, 640, 330, nearestSprite?.texture.key)
                            .setScale(0.6, 0.6)
                            .setDepth(this._notifyPanel.depth + 9)
                    this._notifyPanel
                        .setOkButtonHandler(() => {
                            this.registry.set(`_tutorial-runner-${specificKey ?? key}`, true)
                            this._player?.enableBody(false, this._player?.x, this._player?.y, true, true)
                            this._pet?.enableBody(false, this._pet?.x, this._pet?.y, true, true)
                            this._notifyPanel.hide()
                            this._overlay.hide()
                            this._isScrolling = true
                            this._notifyPanel.resetAddedTo()
                        })
                        .addTo(notifyImage)
                        .setNotification(tutorialText)
                        .show()
                    Log('Tutorial for ' + this._nearestObstacle?.texture.key)
                }
            }

            if (this._nearestObstacleDistance < 400) {
                if (this._nearestObstacle?.texture.key.startsWith('obstacle') && 
                    this._nearestObstacle?.texture.key !== 'obstacle-1') {
                    showTutorial(this._nearestObstacle?.texture.key, this._nearestObstacle, 
                        LocalizedText(this, 'misc', 13, { defaultText: 'Tap to jump over obstacles!' }), 'obstacle')
                }
                else {
                    showTutorial('obstacle-1', this._nearestObstacle, 
                        LocalizedText(this, 'misc', 14, { defaultText: 'Tap twice to double jump over higher obstacles!' }))
                }
            }

            if (this._nearestCollectibleDistance < 400) {
                if (this._nearestCollectible?.texture.key === 'star') {
                    showTutorial('star', 
                                 this._nearestCollectible, 
                                 `Gathering stars adds to your score. \nStars can get your ${this.registry.get('petType')} to grow up too!`)
                }
                else {
                    showTutorial(this._nearestCollectible?.texture.key, 
                                 this._nearestCollectible, 
                                 `Gather special items such as this \nfor your ${this.registry.get('petType')}!`, 
                                 'collectible')
                }
            }
        }

        this._nearestCollectibleDistance = Phaser.Math.MAX_SAFE_INTEGER
        this._nearestObstacleDistance = Phaser.Math.MAX_SAFE_INTEGER

        this._environmentRows.rows[5].environmentPieces.forEach((collectible: Phaser.GameObjects.Sprite) => {
            const distanceFromPlayer: number = Phaser.Math.Distance.Between(
                collectible.x, 
                this._player?.y ?? 0, 
                this._player?.x ?? 0, 
                this._player?.y ?? 0
            )
            if (collectible.x > (this._player?.x ?? 0) && distanceFromPlayer < this._nearestCollectibleDistance) {
                this._nearestCollectibleDistance = distanceFromPlayer
                this._nearestCollectible = collectible
            }

            if (this._player?.isCollidingWith(collectible)) {
                if (collectible.visible) {
                    const collectibleKey: string = collectible.texture.key

                    if (collectibleKey === 'star') {
                        this._starsCollected++
                        GlobalEvents.emit('runner-stars-collected', this._starsCollected)
                    }
                    else {
                        this.registry.set('_collectiblesNew', [
                            ...(this.registry.get('_collectiblesNew') ?? []),
                            collectible.texture.key
                        ])
                    }

                    this.sound.play('runner-collect-stars')
                    this._collectedParticles.emitParticleAt(this._player.x, this._player.y)

                    const _tweenedPiece: Phaser.GameObjects.Sprite 
                        = new Phaser.GameObjects.Sprite(this, this._player.x, this._player.y, collectibleKey)
                    _tweenedPiece.setScale(0.5, 0.5)
                    this.add.existing(_tweenedPiece)
                    this.tweens.add({
                        targets: _tweenedPiece,
                        props: {
                            x: { value: -1000, duration: 1000, ease: 'Linear' },
                            y: { value: -1000, duration: 1000, ease: 'Linear' },
                        }
                    }).on('complete', () => {
                        _tweenedPiece.destroy()
                    })
                    collectible.setVisible(false)
                }

                if (collectible.texture.key !== 'star' && collectible.x < -500) {
                    collectible.texture.key = 'star'
                }
            }
        })
        this._environmentRows.rows[4].environmentPieces.forEach((obstacle: Phaser.GameObjects.Sprite) => {
            const distanceFromPlayer: number = Phaser.Math.Distance.Between(obstacle.x, this._player?.y ?? 0, this._player?.x ?? 0, this._player?.y ?? 0)
            if (obstacle.x > (this._player?.x ?? 0) && distanceFromPlayer < this._nearestObstacleDistance) {
                this._nearestObstacleDistance = distanceFromPlayer
                this._nearestObstacle = obstacle
            }

            const obstacleData: object & IJsonObject = this._obstaclesJsonData[Number(obstacle.texture.key.charAt(obstacle.texture.key.length - 1))]

            if (this._player?.isCollidingWith(obstacle, obstacleData.colWidth, obstacleData.colHeight)) {
                if (obstacle.visible && this._isScrolling) {
                    const movable: boolean = obstacleData.movable

                    if (movable) {
                        this._isScrolling = false

                        this.sound.play('runner-collide-movable')

                        const _tweenedPiece: Phaser.GameObjects.Sprite 
                            = new Phaser.GameObjects.Sprite(this, this._player.x + 20, this._player.y, obstacle.texture.key)
                        _tweenedPiece.setScale(obstacle.scaleX, obstacle.scaleY)
                        this.add.existing(_tweenedPiece)
                        this.time.delayedCall(800, () => {
                            this._isScrolling = true
                        })
                        const _bounceDirectionFactor: boolean = Math.random() > 0.5
                        this.tweens.add({
                            targets: _tweenedPiece,
                            props: {
                                x: { value: this._player.x + (_bounceDirectionFactor ? 1: 0.3) * 300, duration: 300, ease: 'Bounce.easeInOut' },
                                y: { value: this._player.y + (_bounceDirectionFactor ? 1: -7) * 20, duration: 100, ease: 'Bounce.easeInOut' },
                                rotation: { value: (_bounceDirectionFactor ? 1 : -1) * 3.0, duration: 300, ease: 'Bounce.easeIn' }
                            }
                        }).on('complete', () => {
                            this._obstacleParticles.emitParticleAt(_tweenedPiece.x, _tweenedPiece.y)
                            _tweenedPiece.destroy()
                        })
                        obstacle.setVisible(false)

                        this._collidedObstacleCount++
                        Log('Collided obstacles count:', this._collidedObstacleCount)
                    }
                    else if (!this._immovableObstacleCollision) {
                        this._isScrolling = false

                        this.sound.play('runner-collide-immovable')

                        this.time.delayedCall(800, () => {
                            this._player?.setAction(Action.Run)
                            this._isScrolling = true
                        })
                        this.time.delayedCall(1800, () => {
                            this._immovableObstacleCollision = false
                        })
                        this._immovableObstacleCollision = true

                        this._collidedObstacleCount++
                        Log('Collided obstacles count:', this._collidedObstacleCount)
                    }
                }
            }
        })
    }

    private _initRunnerGameObjects(): void {
        this._starsCollected = this.registry.get('_starsCollected') ?? 0

        const groundGroup: Phaser.Physics.Arcade.StaticGroup = this.physics.add.staticGroup().addMultiple([
            this.add.rectangle(
                this.sys.game.canvas.width / 2, 
                640, 
                this.sys.game.canvas.width, 
                this.sys.game.canvas.height - 670, 
                0x000, 
                0 
            ),
            this.add.rectangle(
                this.sys.game.canvas.width / 2, 
                660, 
                this.sys.game.canvas.width, 
                this.sys.game.canvas.height - 670, 
                0x000, 
                0 
            )
        ])

        this._collectedParticles = this.add.particles('star')
        this._collectedParticles.createEmitter({
            x: 0,
            y: 0,
            speed: { min: -800, max: 800 },
            angle: { start: 0, end: 360, steps: 64 },
            scale: { start: 0.3, end: 0 },
            blendMode: 'SCREEN',
            //active: false,
            lifespan: 200,
            gravityY: 500,
            quantity: 64,
            on: false
        })

        this._obstacleParticles = this.add.particles('runner-player-shadow').setDepth(Layer.Play)
        this._obstacleParticles.createEmitter({
            speed: { min: 0, max: 900 },
            // angle: { start: 0, end: 360, steps: 64 },
            scale: { start: 0.5, end: 0 },
            alpha: { start: 1.0, end: 0 },
            blendMode: 'SCREEN',
            //active: false,
            lifespan: 500,
            gravityY: -500,
            quantity: 64,
            on: false
        })

        this._player = new Player(this, 500, 540, this.registry.get('character'), groundGroup.getChildren()[0] as Phaser.GameObjects.Shape)
        this._pet = new Pet(this, 530, 570, this.registry.get('petType'), this.registry.get('petSize'), groundGroup.getChildren()[1] as Phaser.GameObjects.Shape)

        for (let i = 0; i < 4; i++) {
            this._layers[0]?.destroy()
        }
        this._layers = []
        for (let i = 0; i < 4; i++) {
            this._layers.push(this.add.layer().setDepth(i))
        }

        this._road = new Road(this, -220, 750, this._layers[Layer.Background]) 

        const _rowTop = rowTop.filter((row: EnvironmentObject) => !row.isEndPiece)
        const destinationScene: string = setStartAndDestination(_rowTop, this._startingLocation, this._destination)

        const collectiblesRow: Array<EnvironmentObject> = [
            ...generateCollectibles({ startHeight: getRandomInt(60, -200), maxHeight: getRandomInt(200, 600), gap: getRandomInt(25, 100), offsetX: getRandomInt(200, 500)}),
            ...generateCollectibles({ startHeight: getRandomInt(60, -200), maxHeight: getRandomInt(200, 600), gap: getRandomInt(25, 100), offsetX: getRandomInt(200, 500)}),
            ...generateCollectibles({ startHeight: getRandomInt(60, -200), maxHeight: getRandomInt(200, 600), gap: getRandomInt(25, 100), offsetX: getRandomInt(200, 500)}),
            ...generateCollectibles({ startHeight: getRandomInt(60, -200), maxHeight: getRandomInt(200, 600), gap: getRandomInt(25, 100), offsetX: getRandomInt(200, 500)}),
            ...generateCollectibles({ startHeight: getRandomInt(60, -200), maxHeight: getRandomInt(200, 600), gap: getRandomInt(25, 100), offsetX: getRandomInt(200, 500)}),
            ...generateCollectibles({ startHeight: getRandomInt(60, -200), maxHeight: getRandomInt(200, 600), gap: getRandomInt(25, 100), offsetX: getRandomInt(200, 500)}),
            ...generateCollectibles({ startHeight: getRandomInt(60, -200), maxHeight: getRandomInt(200, 600), gap: getRandomInt(25, 100), offsetX: getRandomInt(200, 500)}),
            ...generateCollectibles({ startHeight: getRandomInt(60, -200), maxHeight: getRandomInt(200, 600), gap: getRandomInt(25, 100), offsetX: getRandomInt(200, 500)}),
            ...generateCollectibles({ startHeight: getRandomInt(60, -200), maxHeight: getRandomInt(200, 600), gap: getRandomInt(25, 100), offsetX: getRandomInt(200, 500)}),
            ...generateCollectibles({ startHeight: getRandomInt(60, -200), maxHeight: getRandomInt(200, 600), gap: getRandomInt(25, 100), offsetX: getRandomInt(200, 500)}),
            ...generateCollectibles({ startHeight: getRandomInt(60, -200), maxHeight: getRandomInt(200, 600), gap: getRandomInt(25, 100), offsetX: getRandomInt(200, 500)}),
            ...generateCollectibles({ startHeight: getRandomInt(60, -200), maxHeight: getRandomInt(200, 600), gap: getRandomInt(25, 100), offsetX: getRandomInt(200, 500)}),
        ]
        this._collectiblesData.forEach((c: IJsonObject) => {
            const index: number = getRandomInt(0, collectiblesRow.length / 4)
            if ((Math.random() > 0.25 || index % 3 === 0) && !c.appearedThisRun) {
                collectiblesRow[index].key = c.key
                c.appearedThisRun = true
            }
        })
        this._environmentRows = new EnvironmentRows(
            this,
            [
                { x: 0, y: 360, row: _rowTop, layer: this._layers[Layer.Background] },
                { x: 0, y: 360, row: rowTopEdge, layer: this._layers[Layer.Middle] },
                { x: 480, y: 1050, row: rowBottomEdge, layer: this._layers[Layer.Foreground] },
                { x: 480, y: 1050, row: rowBottom, layer: this._layers[Layer.Foreground] },
                { x: 1280, y: 740, row: generateObstacles(this._obstaclesJsonData), layer: this._layers[Layer.Play], group: true },
                { x: 500, y: 720, row: collectiblesRow, layer: this._layers[Layer.Play], group: true }
            ]
        )

        this._environmentRows.rows[0].on('scroll-stopped', () => {
            this._isScrolling = false 
            this._hasStarted = false
            this.time.addEvent({
                delay: 1500,
                callback: () => {
                    const dailyMissionsScene: DailyMissionsScene = this.scene.get('daily-missions') as DailyMissionsScene

                    this.registry.set('_starsCollected', this._starsCollected)

                    if (destinationScene === 'interact-clinic') {
                        if (this._starsCollected >= 15) 
                            dailyMissionsScene.events.emit('mission-completed', Mission.COLLECT_15_STARS_TO_CLINIC)
                        if (this._starsCollected >= 20) 
                            dailyMissionsScene.events.emit('mission-completed', Mission.COLLECT_20_STARS_TO_CLINIC)
                        if (this._collidedObstacleCount <= 0)
                            dailyMissionsScene.events.emit('mission-completed', Mission.AVOID_ALL_OBSTACLES_TO_CLINIC)
                    }
                    if (destinationScene === 'interact-park') {
                        if (this._starsCollected >= 15) 
                            dailyMissionsScene.events.emit('mission-completed', Mission.COLLECT_15_STARS_TO_PARK)
                        if (this._starsCollected >= 20) 
                            dailyMissionsScene.events.emit('mission-completed', Mission.COLLECT_20_STARS_TO_PARK)
                        if (this._collidedObstacleCount <= 0)
                            dailyMissionsScene.events.emit('mission-completed', Mission.AVOID_ALL_OBSTACLES_TO_PARK)
                    }

                    if (this._destination === Destination.Home && this.registry.get('tutorialFirstTime')) {
                        const tutorialOnlyObjectKeys: Array<string> = this.cache.json.get('collectibles')
                            .filter((c: IJsonObject) => c.tutorialOnly).map((c: IJsonObject) => c.key)
                        this.registry.set('_collectiblesNew', tutorialOnlyObjectKeys)
                    }
                    GlobalEvents.emit('runner-ended')

                    LoadingScene.goToScene(destinationScene, this, defaultDelay, 
                        destinationScene === 'interact-home' ? { startMusic: true, dialog: this.registry.get('tutorialFirstTime') } : null)
                },
                callbackScope: this
            })
        })

        this._layers[Layer.Play].add(this._player)
        this._layers[Layer.Play].add(this._pet)

        this._countdownText = new TextRiffic(this, this.sys.game.canvas.width / 2 - 15, 300, '               ', 60, '#FFC851')
                                .setShadow(2, 4, '#4B4355')

        this._layers[Layer.Foreground].add(this._countdownText)

        this.cameras.main.setBounds(90, -300, 1920 * 2, 600 * 2)
        this.cameras.main.startFollow(this._player, true, 0.1, 0.1, 0, 100)
        this.cameras.main.setZoom(1.14)
        this.cameras.main.setBackgroundColor(0x82C551)
    }

    private _startRunnerCountdown(): void { 
        this._startCountdown = 3
        this.sound.play('runner-countdown')
        this.time.addEvent({ 
            delay: 490, 
            callback: () => {
                this._startCountdown--
                this._countdownText?.setText(LocalizedText(this, 'misc', 9, { defaultText: 'Ready??' }))
                if (this._startCountdown < 0) {
                    this._countdownText?.setText(LocalizedText(this, 'misc', 10, { defaultText: 'It\'s spree time!!!' }))
                }
                if (this._countdownText)
                    this._countdownText.x = this.sys.game.canvas.width / 2 - this._countdownText.width / 2 - 15

            }, 
            callbackScope: this, 
            repeat: 4, 
        })
        this.time.addEvent({
            delay: 3500,
            callback: () => {
                this.sound.play('runner-music', { loop: true })
                this._countdownText?.setText('')
                this._isScrolling = true
                this._hasStarted = true
                this._player?.setAction(Action.Run)
                this._pet?.setAction(Action.Run)
                this.time.removeAllEvents()
                GlobalEvents.emit('runner-started')
            },
            callbackScope: this
        })
    }
}
