export enum Layer {
    // eslint-disable-next-line no-unused-vars
    Background,
    // eslint-disable-next-line no-unused-vars
    Middle, 
    // eslint-disable-next-line no-unused-vars
    Play,
    // eslint-disable-next-line no-unused-vars
    Foreground,
}
