import Phaser from 'phaser'
import Button from './Button'
import DataScene from '../../Scenes/DataScene'

export default class AudioButton extends Button {
    private _sound: Phaser.Sound.BaseSoundManager

    public constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y, 'unmuted-button', ()=>{
            this._sound.volume = this._sound.volume > 0 ? 0 : 1
        })
        this._sound = scene.sound
        this._sound.on('volume', async (_sound: Phaser.Sound.WebAudioSound | Phaser.Sound.HTML5AudioSound, volume: number) => {
            scene.registry.set('volume', volume)
            if (scene.scene.get('data').registry.get('interactables')) {
                await (scene.scene.get('data') as DataScene).saveToFirestore()
            }
            this.image?.setTexture(volume === 0 ? 'muted-button' : 'unmuted-button')
            this.setRotation(volume === 0 ? -0.1 : 0)
        })
    }
}
