import Phaser from 'phaser'
import GlobalEvents from '../../Events/GlobalEvents'

export default class PetSelection extends Phaser.GameObjects.Image {
    private _selected: boolean = false
    public get selected(): boolean {
        return this._selected
    }

    private _petType: 'cat' | 'dog' = 'cat'
    public get petType(): 'cat' | 'dog' {
        return this._petType
    }

    public constructor(
        scene: Phaser.Scene, 
        x: number, 
        y: number, 
        petType: 'cat' | 'dog', 
        sfx: string = 'button-click'
    ) {
        const key: string = `pick-${petType}`
        super(scene, x, y, key)

        this._petType = petType
        
        scene.add.existing(this)
        this.setInteractive()

        this.on('pointerover', () => {
            if (!this._selected) {
                this.setScale(1.1)
                this.setRotation(-0.08)
                this.setDepth(3)
            }
        })
        this.on('pointerout', () => {
            if (!this._selected) {
                this.setScale(1.0)
                this.setRotation(0)
                this.setDepth(0)
            }
        })
        this.on('pointerdown', () => {
            if (!this._selected)
                this.setScale(0.9)
            scene.sound.play(sfx)
        })
        this.on('pointerup', () => {
            if (!this._selected)
                this.setScale(1.1)
            this._selected = !this._selected
            this._checkSelected()
            GlobalEvents.emit('pet-selected', this)
        })

        GlobalEvents.on('pet-selected', (selection: PetSelection) => {
            if (this !== selection) {
                this._selected = false
                this._checkSelected()
            }
        })
    }

    private _checkSelected(isSelected: boolean = this._selected): boolean {
        if (isSelected) {
            this.setScale(1.2)
            this.setRotation(-0.35)
            this.setDepth(999)
            return true
        }
        this.setScale(1.0)
        this.setRotation(0)
        this.setDepth(0)
        return false
    }
}
