import Phaser from 'phaser'
import Button from './Button'
import { Size } from '../../Enums/Size'

export default class Panel extends Phaser.GameObjects.Sprite {
    protected _title?: Phaser.GameObjects.Sprite
    protected _closeButton?: Button

     constructor(
        scene: Phaser.Scene, 
        x: number, 
        y: number, 
        keyTitle: string = '',
        size: Size = Size.Small, 
        keyCloseButton: string = 'button-close'
    ) {
        let key: string = 'panel-small' 
        switch (size) {
            case Size.Small:
                key = 'panel-small'
                break
            
            case Size.Medium:
                key = 'panel-medium'
                break

            case Size.Large:
                key = 'panel-large'
                break
        }
        super(scene, x, y, key)
        this.setInteractive()
        scene.add.existing(this)
        const bounds: Phaser.Geom.Rectangle = this.getBounds()

        if (keyTitle.length > 0) {
            this._title = scene.add.existing(new Phaser.GameObjects.Sprite(
                    scene, 
                    this.x,
                    bounds.y + 10,
                    keyTitle
                )
            ).setInteractive()
        }

        if (keyCloseButton.length > 0) {
            this._closeButton = scene.add.existing(new Button(
                scene,
                bounds.x + bounds.width,
                bounds.y + 10,
                keyCloseButton,
                () => this.hide()
            ))
        }
    }

    public show(value: boolean = true): Panel {
        this.setVisible(value)
        this._title?.setVisible(value)
        this._title?.setDepth(this.depth + 1)
        this._closeButton?.setVisible(value)
        this._closeButton?.setDepth(this.depth + 1)
        this.emit(value ? 'panel-opened' : 'panel-closed')
        this.emit('panel-toggled', value)
        return this
    }

    public hide(): Panel {
        this.show(false)
        return this
    }
}
