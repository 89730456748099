import Phaser from 'phaser'
import Button from '../../GameObjects/UI/Button'
import InteractablesHomeScene from './InteractablesHomeScene'
import NotifyHomeScene from './NotifyHomeScene'
import Log from '../../Utils/Debug'
import IJsonObject from '../../Interfaces/IJsonObject'
import LoadingScene from '../LoadingScene'
import { loadingSettings } from '../../Settings'
const { defaultDelay } = loadingSettings
import DataScene from '../DataScene'
import { Destination } from '../../Enums/Destination'

export default class HomeScene extends Phaser.Scene {
    public constructor() {
        super('interact-home')
    }

    public async create({ startMusic, dialog = false}: { startMusic: boolean, dialog?: boolean }): Promise<void> {
        const { registry } = this
        const interactables = registry.get('interactables')

        this.sound.stopByKey('runner-music')
        if (startMusic) {
            if (!this.sound.get('main-menu-music').isPlaying) {
                this.sound.play('main-menu-music', { loop: true })
            }
        }

        this.add.image(640, 360, 'interact-home-background')

        const interactablesSceneCallback: object = { interactables, callback: (scene: Phaser.Scene) => {
            new Button(scene, 208, 289, 'interact-home-window', () => {}).setDisabled().disableInteractive()

            const petGrowParticles = scene.add.particles('star')
            petGrowParticles.createEmitter({
                x: 0,
                y: 0,
                speed: { min: -1000, max: 1000 },
                angle: { start: 0, end: 360, steps: 64 },
                scale: { start: 0.5, end: 0 },
                blendMode: 'SCREEN',
                //active: false,
                lifespan: 1500,
                gravityY: 500,
                quantity: 64,
                on: false
            })

            if (registry.get('_petGrew')) {
                petGrowParticles.emitParticleAt(interactablesHomeScene.petSprite.x, interactablesHomeScene.petSprite.y + 50)
                this.sound.play('runner-collide-movable')
            }
            registry.set('_petGrew', false)

            const notifyHomeScene: Phaser.Scene = scene.sys.game.scene.getScene('interact-home-notify')?.scene ? 
                scene.sys.game.scene.getScene('interact-home-notify')?.scene.restart({ homeScene: this }).scene :
                scene.scene.add('interact-home-notify', NotifyHomeScene, true, { homeScene: this })
            scene.events.once('shutdown', () => {
                notifyHomeScene.scene?.stop()
            })

            if (startMusic) {
                this.scene.restart({ startMusic: false, dialog: registry.get('tutorialFirstTime') })
            }
            else if (registry.get('tutorialFirstTime')) {
                let toPart2: boolean = false
                let homeTexts1: Array<IJsonObject> = []
                const homeTexts2: Array<IJsonObject> = []
                if (registry.get('_texts')) {
                    registry.get('_texts')['opening']
                        .filter((t: IJsonObject) => 
                            t['pet_type']?.toLowerCase() === registry.get('petType')
                        )
                        .forEach((t: IJsonObject) => {
                            if (t['location']?.toLowerCase() === this.scene.key.split('-')[1] && !toPart2)
                                homeTexts1.push(t)
                            else if (t['location']?.toLowerCase() === 'clinic')
                                toPart2 = true

                            if ((t['location']?.toLowerCase() === this.scene.key.split('-')[1]) && toPart2) {
                                homeTexts2.push(t)
                            }
                        })

                    homeTexts1[0].func = () => {
                        interactablesHomeScene.playerSprite.setVisible(false)
                    }

                    homeTexts1[homeTexts1.length - 1].func = () => {
                        interactablesHomeScene.playerSprite.setVisible(true)
                    }

                    Log(homeTexts1)
                    Log(homeTexts2)

                    if (!registry.get('_collectiblesNew')) {
                        this.scene.launch('dialog', { 
                            dialogTexts: homeTexts1,
                            characterKeys: { player: `character${registry.get('character')}-interact0`, mother: `character${registry.get('character')}-mom0` },
                            parentScenes: [this, this.scene.get('interact-home-notify')]
                        })
                        this.scene.get('dialog').events.once('end', () => {
                            LoadingScene.goToScene('runner', scene, defaultDelay, { startingLocation: Destination.Home, destination: Destination.Clinic })
                        })
                    }
                    else if (dialog) {
                        this.scene.resume('dialog', {
                            dialogTexts: homeTexts2,
                            characterKeys: { player: `character${registry.get('character')}-interact0`, mother: `character${registry.get('character')}-mom0` },
                            parentScenes: [this, this.scene.get('interact-home-notify')]
                        })
                    }
                }
            }
        }, args: ['self'] }
        const interactablesHomeScene: InteractablesHomeScene = (this.sys.game.scene.getScene('interact-home-interactables')?.scene ? 
            this.sys.game.scene.getScene('interact-home-interactables')?.scene.restart(interactablesSceneCallback).scene :
            this.scene.add('interact-home-interactables', InteractablesHomeScene, true, interactablesSceneCallback)) as InteractablesHomeScene
        this.events.once('shutdown', () => {
            interactablesHomeScene.scene?.stop()
        })

        const knowledgeVaultNew = (registry.get('_knowledgeVaultNew') ?? [])
        knowledgeVaultNew.forEach((pickedKnowledge: IJsonObject) => {
            const knowledgeVault: Array<IJsonObject> = registry.get('knowledgeVault') ?? []
            if (!knowledgeVault.find((value: IJsonObject) => value.id === pickedKnowledge.id)) {
                knowledgeVault.push(pickedKnowledge)
                registry.set('knowledgeVault', knowledgeVault.sort((a: IJsonObject, b: IJsonObject) => a.id - b.id))
            }
        })

        const dataScene: DataScene = this.scene.get('data') as DataScene
        await dataScene.refreshData()
    }
}
