export enum Mission {
    // eslint-disable-next-line no-unused-vars
    TAKE_PET_TO_VET = 1,
    // eslint-disable-next-line no-unused-vars
    OPEN_TOPIC_IN_BOOKCASE = 2,
    // eslint-disable-next-line no-unused-vars
    TAKE_PET_TO_PARK = 3,
    // eslint-disable-next-line no-unused-vars
    ANSWER_QUESTION_AT_PARK = 4,
    // eslint-disable-next-line no-unused-vars
    AVOID_ALL_OBSTACLES_TO_PARK = 5,
    // eslint-disable-next-line no-unused-vars
    COLLECT_15_STARS_TO_PARK = 6,
    // eslint-disable-next-line no-unused-vars
    AVOID_ALL_OBSTACLES_TO_CLINIC = 7,
    // eslint-disable-next-line no-unused-vars
    COLLECT_15_STARS_TO_CLINIC = 8,
    // eslint-disable-next-line no-unused-vars
    COLLECT_20_STARS_TO_PARK = 9,
    // eslint-disable-next-line no-unused-vars
    COLLECT_20_STARS_TO_CLINIC = 10,
    // eslint-disable-next-line no-unused-vars
    HEAR_ADVICE_AT_CLINIC = 11
}
