export default class EnvironmentObject {
    private _key: string
    public set key(value: string) {
        this._key = value
    }
    public get key(): string {
        return this._key
    }

    private _offsetX: number
    public get offsetX(): number {
        return this._offsetX
    }

    private _offsetY: number
    public get offsetY(): number {
        return this._offsetY
    }
    
    private _scaleX: number
    public get scaleX(): number {
        return this._scaleX
    }

    private _scaleY: number
    public get scaleY(): number {
        return this._scaleY
    }

    private _isStartPiece: boolean = false
    public get isStartPiece(): boolean {
        return this._isStartPiece
    }

    private _isEndPiece: boolean = false
    public get isEndPiece(): boolean {
        return this._isEndPiece
    }

    public constructor(key: string, offsetX: number, offsetY: number, scaleX: number = 0.5, scaleY: number = 0.5) {
        this._key = key
        this._offsetX = offsetX
        this._offsetY = offsetY
        this._scaleX = scaleX
        this._scaleY = scaleY
    }

    public setAsStartPiece(value: boolean = true): EnvironmentObject {
        this._isStartPiece = value
        return this
    }

    public setAsEndPiece(value: boolean = true): EnvironmentObject {
        this._isEndPiece = value
        return this
    }
}
