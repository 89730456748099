import Phaser from 'phaser'
import Overlay from '../../GameObjects/UI/Overlay'
import Button from '../../GameObjects/UI/Button'
import NotifyPanel from '../../GameObjects/UI/NotifyPanel'
import IJsonObject from '../../Interfaces/IJsonObject'
import DataScene from '../DataScene'

export default class NotifyHomeScene extends Phaser.Scene {
    private _saveToFirestore!: Function

    constructor() {
        super('interact-home-notify')
    }

    public create({ homeScene }: { homeScene: Phaser.Scene }): void {
        this.scene.bringToTop()

        const overlay: Overlay = new Overlay(this)
        this._saveToFirestore = (this.scene.get('data') as DataScene).saveToFirestore

        const interactablesJsonData: Array<IJsonObject> = this.cache.json.get('interactables')
        const collectiblesJsonData: Array<IJsonObject> = this.cache.json.get('collectibles')

        const { registry } = this

        const interactables = registry.get('interactables')

        const collectiblesNew: Array<string> = (registry.get('_collectiblesNew') ?? [])
                .filter((c: string) => collectiblesJsonData.filter((d: IJsonObject) => d.key === c && d.interactable))
        const collectibleNewKey: string = collectiblesNew.pop() as IJsonObject & string
        const interactData: IJsonObject = interactablesJsonData.filter((d: IJsonObject) => d.key === collectibleNewKey)[0]

        if ((interactData || !registry.get('tutorialFirstTime')) && collectibleNewKey) {
            new NotifyPanel(this, 640, 360, interactables.length + 1)
                .setNotification('You have acquired: ')
                .setOkButtonHandler(async () => {
                    // if (registry.get('tutorialFirstTime')) {
                    if (interactables.filter((i: IJsonObject) => i.key === collectibleNewKey).length <= 0) {
                        interactables.push({ key: collectibleNewKey, x: interactData.x, y: interactData.y })
                        registry.set('interactables', interactables)
                    }
                    // }
                    
                    const c: Array<IJsonObject & object> = registry.get('collectibles')
                    c.forEach((o: IJsonObject & object) => {
                        if (o.key === collectibleNewKey) {
                            o.count++
                        }
                    })
                    registry.set('collectibles', c)

                    registry.set('_collectiblesNew', collectiblesNew)
                    if (collectiblesNew.length <= 0) {
                        registry.set('tutorialFirstTime', false)
                    }
                    homeScene.scene.restart({ startMusic: false })

                    if (collectiblesNew.length <= 0) {
                        await this._saveToFirestore()
                    }
                })
                .addTo(new Button(this, 640, 360, collectibleNewKey, () => {}).setDisabled().setDepth(interactables.length + 3).setScale(0.6, 0.6))
            overlay.show()
        }
    }
}
