import Phaser from 'phaser'
import { runnerSettings } from '../../Settings'
const { scrollSpeed: defaultScrollSpeed, rotation: defaultRotation } = runnerSettings
import IScrollable from '../../Interfaces/IScrollable'

export default class Road extends Phaser.GameObjects.Sprite implements IScrollable<Road> {
    private _scrollSpeed: number
    private _roadPieces: Array<Phaser.GameObjects.Sprite> = []
    private _isScrolling: boolean = false

    public constructor(
        scene: Phaser.Scene, 
        x: number, 
        y: number, 
        layer: Phaser.GameObjects.Layer, 
        scrollSpeed: number = defaultScrollSpeed, 
        rotation: number = defaultRotation 
    ) {
        super(scene, x, y, 'runner-road') 
        this._scrollSpeed = scrollSpeed

        this._roadPieces.push(this)
        this._roadPieces.push(new Phaser.GameObjects.Sprite(scene, x + this.width, y, 'runner-road'))
        this._roadPieces.push(new Phaser.GameObjects.Sprite(scene, x + this.width * 2, y, 'runner-road'))

        const roadContainer: Phaser.GameObjects.Container = scene.add.container()

        this._roadPieces.forEach((road: Phaser.GameObjects.Sprite) => {
            const _road: Road = road as Road
            _road.setScale(1.0, 0.6)
            _road._scrollSpeed = scrollSpeed
            roadContainer.add(_road)
            layer.add(roadContainer)
        })
        
        roadContainer.setRotation(rotation)
    }

    public update(_timestep: number, dt: number): void {
        if (this._isScrolling) {
            this._roadPieces.forEach((road: Phaser.GameObjects.Sprite) => {
                const _road: Road = road as Road
                _road.x += 0.01 * _road._scrollSpeed * dt
                
                if (_road.x < -_road.displayWidth ) {
                    _road.x = this._roadPieces[this._roadPieces.length - 1].x + this.displayWidth
                    this._roadPieces.splice(this._roadPieces.indexOf(road), 1)
                    this._roadPieces.push(road)
                }
            })
        }
    }

    public scrolling(value: boolean = true): Road {
        this._isScrolling = value
        return this
    }
}
