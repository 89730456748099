import Phaser from 'phaser'

export default class Overlay extends Phaser.GameObjects.Rectangle {
    private _overlayShown: boolean = false

    constructor(scene: Phaser.Scene, fillColor: number = 0xfff) {
        super(
            scene, 
            640, 
            360,
            2560,
            1440,
            fillColor,
            0.2
        )
        scene.add.existing(this)
        this.setDepth(1)
            .setVisible(this._overlayShown)
            .setInteractive()
            // .on('pointerup', () => {
            //     this.hide()
            // })
    }

    public show(value: boolean = true): Overlay {
        this._overlayShown = value
        this.setVisible(value)
        this.emit(value ? 'overlay-opened' : 'overlay-closed')
        this.emit('overlay-toggled', value)
        return this
    }

    public hide(): Overlay {
        this.show(false)
        return this
    }
}
