import Phaser from 'phaser'
import LanguagePanel from '../GameObjects/UI/LanguagePanel'

export default class LanguageSelectionScene extends Phaser.Scene {
	public constructor() {
		super('language-selection')
	}

    public create(): void {
        this.add.image(640, 360, 'dialog0bg')

        new LanguagePanel(this, 640, 360, 'title-language')
    }
}
